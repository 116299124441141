<!-- Accreditation Pass Form -->
<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <div v-else class="pa-4">
        <!--Instructions-->
        <div :class="$vuetify.breakpoint.width < 1000 ? 'd-flex flex-column' : 'd-flex align-start'">
            <!--Text-->
            <app-text :class="$vuetify.breakpoint.width < 1000 ? 'mb-4' : 'mr-4'" size="small">
                Update the Passes for this Organisation by selecting the Pass Status for each User. Alternatively, use the Passes Configurator for
                more advanced options.
            </app-text>

            <v-spacer />

            <!--Passes Configurator Button-->
            <app-btn
                @click.native="isSetAllDialogVisible = true"
                :class="$vuetify.breakpoint.width < 1000 ? 'align-self-end' : ''"
                label="Passes Configurator"
            />
        </div>

        <!--Selected User-->
        <form-section-title v-if="selectedUser?.userData?.entityId" class="mt-8" title="Selected User" />
        <div v-if="selectedUser?.userData?.entityId" class="mt-4">
            <!--User Info and Zones Row-->
            <div class="d-flex align-center">
                <div class="d-flex flex-grow-1">
                    <avatar class="mr-4" :file="selectedUser.fileData" :size="56" :user="selectedUser.userData" />

                    <!--Name | Position | Status-->
                    <div>
                        <app-text size="normal-bold">{{ selectedUser.userData.userName }}</app-text>
                        <app-text color="grey9" size="small">{{ selectedUser.userData.userPosition }}</app-text>

                        <!--Status - Shown as v-chips-->
                        <div>
                            <v-chip class="mr-2" :color="getStatusConfig(getUserStatus(selectedUser)).color" small text-color="white">
                                {{ selectedUser.passData?.accreditationPassStatus || 'Not Applied' }}
                            </v-chip>
                            <v-chip v-if="selectedUser?.passData?.accreditationPassPrinted" color="green" small text-color="white">Printed </v-chip>
                        </div>
                    </div>
                    <!--Status Buttons-->
                    <div class="d-flex align-center ml-auto">
                        <div
                            v-for="status in statusOptions"
                            :key="status.value"
                            @click="handleStatusSelection(selectedUser, status.value)"
                            class="appWhite cursorPointer rounded-lg ml-4 pa-2"
                            :class="getUserStatus(selectedUser) === status.value && status.color"
                        >
                            <app-icon
                                :color="getUserStatus(selectedUser) === status.value ? 'white' : status.color"
                                :icon="status.icon"
                                :size="$vuetify.breakpoint.width < 600 ? 24 : 32"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!--Zones-->
            <div class="d-flex ml-auto mt-4" style="display: grid !important; grid-template-columns: repeat(5, 1fr); grid-gap: 16px">
                <div
                    v-for="(zone, index) in parseZones(selectedUser.passData?.accreditationPassZones || selectedUser.userData.userDefaultZones)"
                    :key="zone.zone"
                    @click="handleZoneSelection(zone)"
                    class="d-flex flex-column align-center appWhite rounded-lg pa-1 cursorPointer"
                    :class="{ 'disabled-zone': isReadOnly }"
                >
                    <app-text size="small">{{ zone.zone }}</app-text>
                    <app-icon
                        :color="zone.hasAccess ? 'green' : 'red'"
                        :icon="zone.hasAccess ? 'success' : 'cancel'"
                        :size="$vuetify.breakpoint.width < 600 ? 32 : 48"
                    />
                </div>
            </div>
            <!--Status Buttons Row-->
            <!-- <div class="d-flex justify-end mt-4">
                <div
                    v-for="status in statusOptions"
                    :key="status.value"
                    @click="handleStatusSelection(selectedUser, status.value)"
                    class="appWhite cursorPointer rounded-lg ml-4 pa-2"
                    :class="
                        getUserStatus(selectedUser) === status.value &&
                        status.color
                    "
                >
                    <app-icon
                        :color="
                            getUserStatus(selectedUser) === status.value
                                ? 'white'
                                : status.color
                        "
                        :icon="status.icon"
                        size="32"
                    />
                </div>
            </div> -->
        </div>

        <!--Other Users-->
        <!-- <form-section-title
            class="mt-8"
            description="Other Users from your Organisation."
            title="Other Users"
        />
        <app-text
            v-if="!computedUsers.length"
            class="text-center"
            color="grey9"
        >
            There are no other Users that have applied
        </app-text>
        <div v-for="item in computedUsers" :key="item.userData.entityId">
            <div class="d-flex align-center">
                <div class="d-flex flex-grow-1">
                    <avatar
                        class="mr-4"
                        :file="item.fileData"
                        :size="56"
                        :user="item.userData"
                    />
                    <div>
                        <app-text size="normal-bold">{{
                            item.userData.userName
                        }}</app-text>
                        <app-text color="grey9" size="small">{{
                            item.userData.userPosition
                        }}</app-text>

                        <div>
                            <v-chip
                                class="mr-2"
                                :color="
                                    getStatusConfig(
                                        getUserStatus(item)
                                    ).color
                                "
                                small
                                text-color="white"
                            >
                                {{
                                    getUserStatus(item) ||
                                    "Not Applied"
                                }}
                            </v-chip>
                            <v-chip
                                v-if="item?.passData?.accreditationPassPrinted"
                                color="green"
                                small
                                text-color="white"
                                >Printed
                            </v-chip>
                        </div>
                    </div>

                    <div class="d-flex align-center ml-auto">
                        <div
                            v-for="status in statusOptions"
                            :key="status.value"
                            @click="handleStatusSelection(item, status.value)"
                            class="appWhite cursorPointer rounded-lg ml-4 pa-2"
                            :class="
                                getUserStatus(item) === status.value &&
                                status.color
                            "
                        >
                            <app-icon
                                :color="
                                    getUserStatus(item) === status.value
                                        ? 'white'
                                        : status.color
                                "
                                :icon="status.icon"
                                :size="
                                    $vuetify.breakpoint.width < 600 ? 24 : 32
                                "
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="d-flex ml-auto mt-4"
                style="
                    display: grid !important;
                    grid-template-columns: repeat(5, 1fr);
                    grid-gap: 16px;
                "
            >
                <div
                    v-for="(zone, index) in parseZones(
                        item.userData.userDefaultZones
                    )"
                    :key="zone.zone"
                    class="d-flex flex-column align-center appWhite rounded-lg pa-1"
                >
                    <app-text size="small">{{ zone.zone }}</app-text>
                    <app-icon
                        :color="zone.iconColor"
                        :icon="zone.iconName"
                        :size="$vuetify.breakpoint.width < 600 ? 32 : 48"
                    />
                </div>
            </div>

            <v-divider class="greyD my-4" />
        </div> -->

        <!--Save Button-->
        <div class="d-flex justify-end mt-4">
            <app-btn @click.native="savePasses" color="green" :disabled="computedIsButtonDisabled" icon="save" label="Save" />
        </div>

        <!--Dialogs ------------------------------------------------------------------------------------------------ -->
        <v-dialog v-if="isSetAllDialogVisible" max-width="600" v-model="isSetAllDialogVisible">
            <div class="appGrey rounded-lg pa-4">
                <!--Title-->
                <app-text color="primary" size="medium-bold">Set All Configurator</app-text>

                <v-divider class="mt-4" />

                <!--Body Text-->
                <app-text class="mt-4"> Select the Pass status change you wish to apply from the configurator below. </app-text>

                <!--'Please Note' Message-->
                <div class="d-flex align-center mt-4">
                    <app-icon class="mr-4" color="orange" icon="error" size="32" />
                    <app-text size="small">
                        <b>Please note:</b>
                        Where applicable, this can change the Pass status for all Users in your Organisation, and can also create Passes for Users who
                        have not yet applied.
                        <br />
                        <i>Please check your selection before updating.</i>
                    </app-text>
                </div>

                <!--Status - From-->
                <div class="mt-4">
                    <app-text size="normal-bold">Change all</app-text>
                    <app-form-field form-type="select" :items="getSetAllPassesFromOptions()" item-text="value" v-model="setAllPassesFrom" />
                </div>

                <!--Status - To-->
                <div class="mt-4">
                    <app-text size="normal-bold">To</app-text>
                    <app-form-field
                        form-type="select"
                        :items="['Approved', 'Pending', 'Rejected', 'Reserved', 'Not Applied']"
                        item-text="value"
                        v-model="setAllPassesTo"
                    />
                </div>

                <div v-if="setAllPassesFrom && setAllPassesTo">
                    <v-divider class="mt-4" />

                    <!--If selections are the same-->
                    <app-text v-if="setAllPassesFrom === setAllPassesTo" class="mt-4" color="red">
                        You cannot set <b>{{ setAllPassesFrom }}</b> to <b>{{ setAllPassesTo }}</b
                        >.
                    </app-text>

                    <div v-else>
                        <app-text class="mt-4">
                            Are you sure you want to change all from
                            <b>{{ setAllPassesFrom }}</b> to <b>{{ setAllPassesTo }}</b
                            >?
                        </app-text>

                        <ul>
                            <li v-if="setAllPassesFrom === 'Not Applied'">
                                <app-text class="mt-4" color="red"> This will also create Passes for these Users </app-text>
                            </li>
                        </ul>

                        <!--Buttons-->
                        <div class="d-flex justify-space-between mt-4">
                            <!--No-->
                            <app-btn @click.native="isSetAllDialogVisible = false" color="grey" icon="cancel" label="No" />

                            <!--Yes-->
                            <app-btn @click.native="updatePassesFromConfigurator" color="green" icon="success" label="Yes" />
                        </div>
                    </div>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
/////////////////////////////////////
//// ! Accreditation Pass Form ! ////
/////////////////////////////////////

export default {
    name: 'AccreditationPassForm',

    props: {
        accreditationData: {
            type: Object,
            required: true
        },
        selectedUser: {
            type: Object,
            required: true
        },
        selectedEvent: {
            type: Object,
            required: true
        },
        isReadOnly: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        isLoading: true,
        isRemovedUsersDialogVisible: false,
        isSetAllDialogVisible: false,
        organisationUsers: {},
        originalUsersAndStatuses: '',
        removedUserNames: [],
        selectedUsers: [],
        statusOptions: [
            { value: 'Approved', color: 'green', icon: 'success' },
            { value: 'Pending', color: 'orange', icon: 'pending' },
            { value: 'Rejected', color: 'red', icon: 'cancel' },
            { value: 'Reserved', color: 'blue', icon: 'reserved' }
        ],
        usersAndStatuses: [],
        setAllPassesFrom: '',
        setAllPassesTo: ''
    }),

    computed: {
        /**
         * Computed Is Button Disabled
         *
         * Return true if the selectedUsers array is the same as the original, false otherwise.
         *
         * @returns {boolean}
         */
        computedIsButtonDisabled() {
            const t = this
            const ORIGINAL_USERS_AND_STATUSES = t.originalUsersAndStatuses
            const USERS_AND_STATUSES = t.usersAndStatuses

            // Compare both the status and zones
            const hasChanges = ORIGINAL_USERS_AND_STATUSES !== JSON.stringify(USERS_AND_STATUSES)

            return !hasChanges
        },

        /**
         * Computed Users
         *
         * Return an array of Users from the selected User's Organisation.
         * The array is sorted into two groups: Users who have applied for a Pass, and Users who have not applied for a Pass.
         *
         * @returns {*[]}
         */
        computedUsers() {
            const t = this
            let usersApplied = t.organisationUsers.userPassData?.filter((item) => item.passData?.accreditationPassStatus)
            let usersNotApplied = t.organisationUsers.userPassData?.filter((item) => !item.passData?.accreditationPassStatus)
            let tableData = [...usersApplied, ...usersNotApplied]

            // Remove the current user, if they are in the list
            if (t.$props.selectedUser.userData?.entityId)
                tableData = tableData.filter((item) => item.userData.entityId !== t.selectedUser.userData.entityId)

            return tableData
        }
    },

    methods: {
        /**
         * Handle Zone Selection
         *
         * Toggle the access for the selected zone and update the pass
         *
         * @param zone {Object} - The zone to update
         */
        handleZoneSelection(zone) {
            const t = this

            if (t.isLoading || t.isReadOnly) return

            // Get current zones
            const currentZones = t.parseZones(t.selectedUser.passData?.accreditationPassZones || t.selectedUser.userData.userDefaultZones)

            // Find and update the matching zone
            const updatedZones = currentZones.map(
                (z) => (z.zone === zone.zone ? { ...z, hasAccess: !z.hasAccess } : z) // Toggle the access here
            )

            // Update the usersAndStatuses array
            const userStatus = t.usersAndStatuses.find((item) => item.userData.entityId === t.selectedUser.userData.entityId)
            if (userStatus) {
                userStatus.accreditationPassZones = JSON.stringify(updatedZones)
            }

            // Update the selectedUser's zones directly to trigger UI update
            if (t.selectedUser.passData) {
                t.selectedUser.passData.accreditationPassZones = JSON.stringify(updatedZones)
            } else {
                // If no passData exists yet, update userDefaultZones
                t.selectedUser.userData.userDefaultZones = JSON.stringify(updatedZones)
            }
        },

        /**
         * Parse Zones
         *
         * Parse the zones string into an array of zone objects
         *
         * @param zonesData {String|Array} - The zones data to parse
         * @returns {Array} - Array of zone objects
         */
        parseZones(zonesData) {
            let result = []

            // If the data is a string, parse it
            if (typeof zonesData === 'string') {
                try {
                    result = JSON.parse(zonesData)
                } catch (error) {
                    console.error('Error parsing zones:', error)
                }
            }
            // If the data is already an array, use it
            else if (Array.isArray(zonesData)) {
                result = zonesData
            }

            return result
        },
        /**
         * Confirm Delete Passes
         *
         * On confirming the removal of passes, emit the savePasses event back to the parent.
         */
        confirmDeletePasses() {
            const t = this

            t.isRemovedUsersDialogVisible = false

            t.$emit('emitSavePasses', t.selectedUsers)
        },

        /**
         * Get Set All Passes 'From' Options
         *
         * Return an array of pass statuses that are currently in use.
         *
         * @returns {string[]}
         */
        getSetAllPassesFromOptions() {
            const t = this
            let options = ['Approved', 'Pending', 'Rejected', 'Reserved', 'Not Applied']

            // Populate the options array with the pass statuses that are currently in use
            options = options.filter((item) => t.usersAndStatuses.find((u) => u.passStatus === item))

            return options
        },

        /**
         * Get Status Config
         *
         * Return the colour and icon for the pass status.
         *
         * @returns {{color: string, icon: string}}
         * @param status {String} - The pass status
         */
        getStatusConfig(status) {
            let color
            let icon

            switch (status) {
                case 'Approved':
                    color = 'green'
                    icon = 'success'
                    break
                case 'Rejected':
                    color = 'red'
                    icon = 'cancel'
                    break
                case 'Pending':
                    color = 'orange'
                    icon = 'pending'
                    break
                case 'Reserved':
                    color = 'blue'
                    icon = 'reserved'
                    break
                default:
                    color = 'grey9'
                    icon = 'help'
                    break
            }

            return { color, icon }
        },

        /**
         * Get User Status
         *
         * Return the pass status for the selected user.
         *
         * @param user {Object} - The pass object {fileData{}, passData{}, userData{}}
         * @returns {string|String|*}
         */
        getUserStatus(user) {
            return this.usersAndStatuses.find((u) => u.userData.entityId === user.userData.entityId)?.passStatus
        },

        /**
         * Handle User Selection
         *
         * Add or remove the selectedUser from the selectedUsers array.
         * If all users are selected, check the "Select All" checkbox.
         * If all users are not selected, uncheck the "Select All" checkbox.
         *
         * @param item {Object} - The pass object {fileData{}, passData{}, userData{}}
         */
        handleUserSelection(item) {
            const t = this

            // If the user is already selected, remove them from the array
            if (t.selectedUsers.includes(item.userData.entityId)) t.selectedUsers = t.selectedUsers.filter((user) => user !== item.userData.entityId)
            // Otherwise, add them to the array
            else t.selectedUsers.push(item.userData.entityId)
        },

        /**
         * Handle Status Selection
         *
         * Update the pass status for the selected user, to update the UI.
         *
         * @param user {Object} - The pass object {fileData{}, passData{}, userData{}}
         * @param status {String} - The new pass status
         */
        handleStatusSelection(user, status) {
            const t = this

            // Update the pass status for the selected user
            t.usersAndStatuses.find((item) => item.userData.entityId === user.userData.entityId).passStatus = status
        },

        /**
         * Save Passes
         *
         * Compare the original and updated arrays.
         * If there are updated items, emit the savePasses event back to the parent.
         */
        savePasses() {
            const t = this

            // If the button is disabled, do nothing
            if (t.computedIsButtonDisabled) return

            const ORIGINAL_USERS_AND_STATUSES = JSON.parse(t.originalUsersAndStatuses)
            const USERS_AND_STATUSES = t.usersAndStatuses
            let updatedItems = []

            // Compare the original and updated arrays
            USERS_AND_STATUSES.forEach((currentUserStatus) => {
                // Find the original equivalent to the current currentUserStatus
                const ORIGINAL_USER_STATUS = ORIGINAL_USERS_AND_STATUSES.find((o) => o.userData.entityId === currentUserStatus.userData.entityId)

                // Check if either status or zones have changed
                if (
                    ORIGINAL_USER_STATUS &&
                    (JSON.stringify(currentUserStatus) !== JSON.stringify(ORIGINAL_USER_STATUS) ||
                        currentUserStatus.accreditationPassZones !== ORIGINAL_USER_STATUS.accreditationPassZones)
                ) {
                    updatedItems.push(currentUserStatus)
                }
            })

            // If there are updated items, emit the savePasses event back to the parent
            if (updatedItems.length) {
                t.$emit('emitSavePasses', {
                    usersAndStatuses: updatedItems,
                    accreditationPassEventId: t.selectedEvent.eventData.entityId,
                    accreditationPassOrganisationId: t.selectedUser.organisationData.entityId
                })
            }
        },

        /**
         * Update Passes From Configurator
         *
         * Sort and call to update the pass statuses for all selected Users in the Organisation.
         */
        updatePassesFromConfigurator() {
            const t = this
            const ALL_FROM = t.setAllPassesFrom
            const ALL_TO = t.setAllPassesTo
            const USERS_AND_STATUSES = t.usersAndStatuses

            USERS_AND_STATUSES.forEach((item) => {
                if (item.passStatus === ALL_FROM) item.passStatus = ALL_TO
            })

            t.isSetAllDialogVisible = false
        },

        /**
         * Get Zone Config
         *
         * Returns the zone configuration based on access status.
         *
         * @param zone {Object} - The zone object containing access information
         * @returns {{color: string, icon: string}} - The zone configuration
         */
        getZoneConfig(zone) {
            let color
            let icon

            if (zone.hasAccess) {
                color = 'green'
                icon = 'success'
            } else {
                color = 'red'
                icon = 'cancel'
            }

            return { color, icon }
        },

        /**
         * Parse Zones
         *
         * Parses the userDefaultZones data and returns formatted zone information.
         *
         * @param zonesData {string|Array} - The zones data to parse
         * @returns {Array} - Formatted zone information with icons and colors
         */
        parseZones(zonesData) {
            let result = []

            // If the data is a string, parse it
            if (typeof zonesData === 'string') {
                try {
                    result = JSON.parse(zonesData)
                } catch (error) {
                    console.error('Error parsing userDefaultZones: ', error)
                }
            }
            // If the data is an array, return it as is
            else if (Array.isArray(zonesData)) result = zonesData

            // Attach the config directly to each zone
            result = result.map((zone) => {
                const config = this.getZoneConfig(zone)
                return {
                    ...zone,
                    iconColor: config.color,
                    iconName: config.icon
                }
            })

            return result
        }
    },

    /**
     * Mounted
     *
     * Get the data for the selected User's Organisation.
     * Create a new array of objects with the User data and Pass status.
     * This is used to display the User data and Pass status in the UI's Status Buttons.
     */
    mounted() {
        const t = this

        t.isLoading = true

        // Get the data for the selected User's Organisation
        t.organisationUsers = t.$props.accreditationData.passesData.filter(
            (item) => item.organisationData.entityId === t.$props.selectedUser.organisationData.entityId
        )?.[0]

        // Create a new array of objects with the User data, Pass status and zones
        t.organisationUsers.userPassData.forEach((item) => {
            t.usersAndStatuses.push({
                userData: item.userData,
                passStatus: item.passData?.accreditationPassStatus || 'Not Applied',
                accreditationPassZones: item.passData?.accreditationPassZones || item.userData.userDefaultZones
            })
        })
        t.originalUsersAndStatuses = JSON.stringify(t.usersAndStatuses)

        t.isLoading = false
    }
}
</script>

<style scoped>
.disabled-zone {
    opacity: 0.7;
    cursor: not-allowed;
}

.cursorPointer {
    cursor: pointer;
}
</style>
