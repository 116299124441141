<template>
    <div>
        <!--Header-->
        <div class="d-flex">
            <!--Back Button-->
            <app-btn @click.native="handleBack" class="mr-4" color="white" icon="arrowBack" icon-color="primary" />

            <page-title icon="accreditation" info="Select an Organisation to manage Accreditation Users." title="Accreditation - Organisations" />
        </div>

        <v-divider class="mt-2" />

        <!--Organisation Figures-->
        <div
            v-if="fullEventData?.accreditationEventData?.entityId && $vuetify.breakpoint.width >= 600"
            class="mt-4"
            style="display: grid; grid-template-columns: repeat(3, 1fr); grid-gap: 16px"
        >
            <!--Total Organisations-->
            <div class="d-flex flex-column align-center black rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{ computedTotalOrganisations }}</app-text>
                <app-text color="white" size="small">Total Organisations</app-text>
            </div>

            <!--Active Organisations-->
            <div class="d-flex flex-column align-center green rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{ computedActiveOrganisations }}</app-text>
                <app-text color="white" size="small">Active Organisations</app-text>
            </div>

            <!--Inactive Organisations-->
            <div class="d-flex flex-column align-center grey rounded-lg pa-4">
                <app-text color="white" size="large-bold">{{ computedInactiveOrganisations }}</app-text>
                <app-text color="white" size="small">Inactive Organisations</app-text>
            </div>
        </div>

        <!--Action Bar-->
        <div class="d-flex align-center mt-4">
            <!--Search-->
            <app-form-field
                form-type="textInput"
                append-icon="icons8-search"
                class="mr-4"
                :clearable="true"
                label="Organisation Name"
                style="width: 100%"
                v-model.trim="searchByOrgName"
            />

            <!--Filter Button - with a numbered badge-->
            <div style="position: relative">
                <app-btn @click.native="toggleFiltersVisibility" class="mr-4" color="appWhite" icon="filter" icon-color="primary" />
                <span v-if="computedNumberOfActiveFilters > 0" class="badge">{{ computedNumberOfActiveFilters }}</span>
            </div>
            <!--More Actions Menu-->
            <more-actions-menu @emitMenuSelection="handleEmittedMenuSelection" :menuList="computedMenuOptions" />
        </div>

        <!--Clear Filters-->
        <div v-if="computedNumberOfActiveFilters > 0" class="d-flex justify-end mt-4">
            <app-btn @click.native="clearFilters" icon="close" label="Clear Filters" />
        </div>

        <!--Getting Started Info-->
        <div
            v-if="!fullEventData?.accreditationEventData?.entityId"
            class="d-flex align-center justify-center"
            :style="$vuetify.breakpoint.width < 600 ? 'height: 50vh' : 'height: 50vh'"
        >
            <page-start-information title="Getting Started" :points="['Configure this event', 'Set it to Live']" />
        </div>

        <!--Table-->
        <v-data-table
            v-if="$vuetify.breakpoint.width >= 600 && fullEventData?.accreditationEventData?.entityId"
            class="appWhite rounded-lg mt-4"
            :headers="computedHeaders"
            :items="computedTableData"
        >
            <!--Organisation Name-->
            <template v-slot:item.organisationName="{ item }">
                <app-text size="small">{{ item.organisationData.organisationName }}</app-text>
            </template>

            <!--Total Users-->
            <template v-slot:item.totalUsers="{ item }">
                <app-text size="small">{{ item.userPassData.length }}</app-text>
            </template>

            <!--Active Users-->
            <template v-slot:item.activeUsers="{ item }">
                <app-text size="small">{{ getActiveUsers(item.userPassData) }}</app-text>
            </template>

            <!--Action Button-->
            <template v-slot:item.action="{ item }">
                <app-icon @click.native="handleOrganisationSelection(item)" class="cursorPointer" color="primary" icon="arrowForward" />
            </template>
        </v-data-table>

        <!--Mobile Cards-->
        <div v-if="$vuetify.breakpoint.width < 600">
            <!--No data message-->
            <app-text v-if="!computedTableData.length" class="text-center mt-4" color="grey9">No Organisations found </app-text>

            <!--Cards-->
            <div
                v-for="(item, index) in computedTableData"
                :key="index"
                @click="handleOrganisationSelection(item)"
                class="appWhite cursorPointer rounded-lg mt-4 pa-4"
            >
                <app-text size="normal-bold">{{ item.organisationData.organisationName }}</app-text>
                <div class="d-flex mt-2">
                    <app-text class="mr-4" color="grey9" size="small">Total Users: {{ item.userPassData.length }}</app-text>
                    <app-text color="grey9" size="small">Active Users: {{ getActiveUsers(item.userPassData) }}</app-text>
                </div>
            </div>
        </div>

        <!--Filters Panel-->
        <filter-panel :is-filters-panel-visible="isFiltersPanelVisible" @toggle="toggleFiltersVisibility">
            <div class="pa-4">
                <!--Active Status-->
                <page-break-title title="Status" />
                <div class="mt-4">
                    <app-form-field
                        form-type="autoComplete"
                        :items="['Active', 'Inactive']"
                        label="Organisation Status"
                        :multiple="true"
                        :small-chips="true"
                        v-model="filterByStatus"
                    />
                </div>
            </div>
        </filter-panel>
    </div>
</template>

<script>
export default {
    name: 'AccreditationOrganisations',

    props: ['fullEventData'],

    data: () => ({
        isFiltersPanelVisible: false,
        filterByStatus: [],
        searchByOrgName: '',
        tableHeaders: [
            {
                text: 'Organisation',
                value: 'organisationName',
                align: 'start',
                sortable: false
            },
            {
                text: 'Total Users',
                value: 'totalUsers',
                align: 'start',
                sortable: false
            },
            {
                text: 'Active Users',
                value: 'activeUsers',
                align: 'start',
                sortable: false
            },
            {
                text: '',
                value: 'action',
                align: 'right',
                sortable: false,
                width: '48px'
            }
        ]
    }),

    computed: {
        /**
         * Computed Headers
         *
         * Return the table headers
         *
         * @returns {Array} headers array
         */
        computedHeaders() {
            return this.tableHeaders
        },

        /**
         * Computed Number of Active Filters
         *
         * Count the number of active filters
         *
         * @returns {Number} number of active filters
         */
        computedNumberOfActiveFilters() {
            const t = this
            return [t.filterByStatus.length].reduce((acc, curr) => acc + curr, 0)
        },

        /**
         * Computed Table Data
         *
         * Filter and sort the organisations data
         *
         * @returns {Array} filtered and sorted organisations
         */
        computedTableData() {
            const t = this
            let organisationsData = t.$props.fullEventData?.passesData || []

            // Search by Organisation Name
            if (t.searchByOrgName) {
                const SEARCH_TERM = t.searchByOrgName.toUpperCase()
                organisationsData = organisationsData.filter((item) => {
                    const ORG_NAME = item.organisationData?.organisationName?.toUpperCase() || ''
                    return ORG_NAME.includes(SEARCH_TERM)
                })
            }

            // Filter by Status
            if (t.filterByStatus.length) {
                organisationsData = organisationsData.filter((item) => {
                    const IS_ACTIVE = t.getActiveUsers(item.userPassData) > 0
                    return t.filterByStatus.includes(IS_ACTIVE ? 'Active' : 'Inactive')
                })
            }

            // Sort by Organisation Name
            organisationsData = organisationsData.sort((a, b) => (a.organisationData.organisationName > b.organisationData.organisationName ? 1 : -1))

            return organisationsData
        },

        /**
         * Computed Total Organisations
         *
         * @returns {Number} total number of organisations
         */
        computedTotalOrganisations() {
            return this.fullEventData?.passesData?.length || 0
        },

        /**
         * Computed Active Organisations
         *
         * @returns {Number} number of organisations with active users
         */
        computedActiveOrganisations() {
            const t = this
            return t.fullEventData?.passesData?.filter((org) => t.getActiveUsers(org.userPassData) > 0).length || 0
        },

        /**
         * Computed Inactive Organisations
         *
         * @returns {Number} number of organisations with no active users
         */
        computedInactiveOrganisations() {
            const t = this
            return t.fullEventData?.passesData?.filter((org) => t.getActiveUsers(org.userPassData) === 0).length || 0
        },
        /**
         * Computed Menu Options
         *
         * Return the menu options based on the event status.
         *
         * @returns {*[]} an array of menu options
         */
        computedMenuOptions() {
            const t = this
            let menuOptions = []

            menuOptions.push({ name: 'Event Settings', icon: 'settings' })
            menuOptions.push({ name: 'Export', icon: 'export' })

            // Only show these options if the event is live
            if (t.$props.fullEventData?.accreditationEventData?.entityId) {
                menuOptions.push({ name: 'Print Manager', icon: 'print' })
            }

            return menuOptions
        }
    },

    methods: {
        /**
         * Clear Filters
         *
         * Reset all filters to default values
         */
        clearFilters() {
            this.filterByStatus = []
        },

        /**
         * Handle Emitted Menu Selection
         *
         * Take the emitted selection from the menu, and call the relevant function.
         *
         * @param selection a string-based menu option
         */
        handleEmittedMenuSelection(selection) {
            const t = this

            if (selection === 'Event Settings') t.$emit('openEventSettings', t.$props.fullEventData)
            if (selection === 'Export') t.exportData()
            if (selection === 'Print Manager') t.$emit('openPrintManager', t.$props.fullEventData)
        },
        /**
         * Get Active Users
         *
         * Count number of users with active passes
         *
         * @param {Array} userPassData array of user pass data
         * @returns {Number} count of active users
         */
        getActiveUsers(userPassData) {
            return userPassData.filter((user) => user.passData?.accreditationPassStatus && user.passData?.accreditationPassStatus !== 'Rejected')
                .length
        },

        /**
         * Handle Organisation Selection
         *
         * Emit selected organisation to parent
         *
         * @param {Object} organisation selected organisation data
         */
        handleOrganisationSelection(organisation) {
            this.$emit('selectedOrganisation', organisation)
        },

        /**
         * Toggle Filters Visibility
         *
         * Show/hide filters panel
         */
        toggleFiltersVisibility() {
            this.isFiltersPanelVisible = !this.isFiltersPanelVisible
        },

        /**
         * Handle Back
         *
         * Navigate back to events view
         */
        handleBack() {
            this.$emit('backToEvents')
        }
    }
}
</script>

<style scoped>
.badge {
    position: absolute;
    top: -8px;
    right: 8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
</style>
