<!-- Accreditation -->
<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <div v-else>
        <!--Events-->
        <accreditation-events v-if="viewType === 'Events'" @selectedEvent="handleSelectedEvent" :full-events-data="fullAccreditationEventsData" />

        <!--Organisations-->
        <accreditation-organisations
            v-if="viewType === 'Organisations'"
            @openEventSettings="handleOpenEventSettings"
            @selectedOrganisation="handleSelectedOrganisation"
            @backToEvents="handleBack"
            :full-event-data="fullSingleAccreditationEventData"
        />

        <!--Users-->
        <accreditation-users
            v-if="viewType === 'Users'"
            @openPrintManager="handleOpenPrintManager"
            @selectedUser="handleSelectedUser"
            @backToEvents="handleBack"
            :full-event-data="fullSingleAccreditationEventData"
            :selected-organisation="selectedOrganisation"
        />

        <!--Right Side --------------------------------------------------------------------------------------------- -->
        <transition
            enter-active-class="animate__animated animate__fadeInRight animate__faster"
            leave-active-class="animate__animated animate__fadeOutRight animate__faster"
            mode="out-in"
        >
            <div
                v-if="isRightPanelVisible"
                class="appGrey formShadow"
                style="position: absolute; top: 0; bottom: 0; right: 0; overflow: auto"
                :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
            >
                <!--Event Settings Form-->
                <div v-if="formType === 'eventSettingsForm'">
                    <!--Header-->
                    <div class="d-flex align-center primary pa-4">
                        <app-text color="appWhite" size="normal">Event Settings</app-text>
                        <v-spacer />
                        <app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32" />
                    </div>

                    <v-divider />

                    <!--Event Settings Form-->
                    <accreditation-event-settings-form @saveEventSettings="saveEventSettings" :selected-event="selectedEvent" />
                </div>

                <!--Passes Form-->
                <div v-if="formType === 'PassForm'">
                    <!--Header-->
                    <div class="d-flex align-center primary pa-4">
                        <app-text color="appWhite" size="normal">Passes</app-text>
                        <v-spacer />
                        <app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32" />
                    </div>

                    <v-divider />

                    <!--Passes Form-->
                    <accreditation-pass-form
                        @emitSavePasses="savePasses"
                        :accreditation-data="fullSingleAccreditationEventData"
                        :selected-user="selectedUser"
                        :selected-event="selectedEvent"
                        :is-read-only="false"
                    />
                </div>

                <!--Print Form-->
                <div v-if="formType === 'PrintForm'">
                    <!--Header-->
                    <div class="d-flex align-center primary pa-4">
                        <app-text color="appWhite" size="normal">Print Manager</app-text>
                        <v-spacer />
                        <app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32" />
                    </div>

                    <v-divider />

                    <!--Print Form-->
                    <accreditation-print-manager
                        @reloadData="reloadData"
                        @emitUpdatePassPrintStatuses="updatePassPrintStatuses"
                        :accreditation-data="fullSingleAccreditationEventData"
                    />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import AccreditationEvents from '@/views/accreditation/staff/accreditationEvents/AccreditationEvents.vue'
import AccreditationOrganisations from '@/views/accreditation/staff/accreditationOrganisations/AccreditationOrganisations.vue'
import AccreditationUsers from '@/views/accreditation/staff/accreditationUsers/AccreditationUsers.vue'
import AccreditationEventSettingsForm from '@/views/accreditation/staff/accreditationEventSettingsForm/AccreditationEventSettingsForm.vue'
import AccreditationPassForm from '@/views/accreditation/staff/accreditationPassForm/AccreditationPassForm.vue'
import AccreditationPrintManager from '@/views/accreditation/staff/accreditationPrintManager/AccreditationPrintManager.vue'

export default {
    name: 'Accreditation',

    components: {
        AccreditationPrintManager,
        AccreditationPassForm,
        AccreditationEventSettingsForm,
        AccreditationUsers,
        AccreditationOrganisations, // Add new component
        AccreditationEvents
    },

    data: () => ({
        formType: '',
        isLoading: false,
        isRightPanelVisible: false,
        selectedEvent: {},
        selectedOrganisation: {}, // Add selected organisation
        selectedUser: {},
        viewType: 'Events',

        // Data
        fullAccreditationEventsData: [],
        fullSingleAccreditationEventData: {}
    }),

    methods: {
        /**
         * Close Right Panel
         *
         * Close the right panel.
         */
        closeRightPanel() {
            const t = this

            t.isRightPanelVisible = false
        },

        /**
         * Reload Data
         *
         * Reload the data to refresh the page.
         */
        async reloadData() {
            const t = this

            t.isLoading = true

            await Promise.all([t.loadData(), t.loadFullSingleAccreditationEventData()])

            t.isLoading = false
        },

        /**
         * Handle Open Event Settings
         *
         * Handle the opening of the Event Settings dialog.
         *
         * @param payload {object} the selected event and the page view name
         */
        handleOpenEventSettings(payload) {
            const t = this

            t.formType = 'eventSettingsForm'

            t.openRightPanel()
        },

        handleOpenPrintManager(payload) {
            const t = this

            t.formType = 'PrintForm'

            t.openRightPanel()
        },

        /**
         * Handle Selected Event
         *
         * Update selected event and switch to Organisations view
         *
         * @param payload {object} the selected event
         */
        handleSelectedEvent(payload) {
            const t = this

            t.selectedEvent = { ...payload.selectedEvent }

            // If there is no accreditationEventData, set some defaults
            if (!t.selectedEvent?.accreditationEventData?.entityId) {
                t.selectedEvent.accreditationEventData = {
                    accreditationEventStatus: 'Pending',
                    accreditationEventPhotoRequirement: 'Not Required'
                }
            }

            // Change view to Organisations instead of Users
            t.viewType = 'Organisations'

            t.loadFullSingleAccreditationEventData()
        },

        /**
         * Handle Selected Organisation
         *
         * Store selected organisation and switch to Users view
         *
         * @param organisation {object} the selected organisation
         */
        handleSelectedOrganisation(organisation) {
            const t = this

            t.selectedOrganisation = organisation
            t.viewType = 'Users'
        },

        /**
         * Handle Back Navigation
         *
         * Navigate back to previous view based on current view
         */
        handleBack() {
            const t = this

            switch (t.viewType) {
                case 'Organisations':
                    t.viewType = 'Events'
                    t.selectedEvent = {}
                    break
                case 'Users':
                    t.viewType = 'Organisations'
                    t.selectedOrganisation = {}
                    break
            }
        },

        handleSelectedUser(payload) {
            const t = this

            t.selectedUser = { ...payload.selectedUser }

            t.formType = payload.formType

            t.openRightPanel()
        },

        /**
         * Load Data
         *
         * Load all the data required for the page.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this

            t.isLoading = true

            await Promise.all([t.loadFullAccreditationEventsData()])

            t.isLoading = false
        },

        /**
         * Load Full Accreditation Events Data
         *
         * Load the required data for all the Accreditation Events (Live Events):
         *  - Accreditation Events data
         *  - Events data
         *
         * @returns {Promise<void>}
         */
        async loadFullAccreditationEventsData() {
            const t = this

            t.isLoading = true

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getFullAccreditationEventsData()

            t.isLoading = false

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Full Accreditation Events data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Events data, please try again.'
                return
            }

            // Assign the data
            t.fullAccreditationEventsData = RESPONSE.data
        },

        /**
         * Load Full Single Accreditation Event Data
         *
         * Load the required data for a single Accreditation Event:
         *  - Accreditation Event data
         *  - Event data
         *
         * @returns {Promise<void>}
         */
        async loadFullSingleAccreditationEventData() {
            const t = this

            t.isLoading = true

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getFullSingleAccreditationEventData(t.selectedEvent.eventData.entityId)

            t.isLoading = false

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Full Single Accreditation Event data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Accreditation data, please try again.'
                return
            }

            t.fullSingleAccreditationEventData = RESPONSE.data

            t.closeRightPanel()
        },

        /**
         * Open Form
         *
         * Open the form dialog.
         */
        openRightPanel() {
            const t = this

            t.isRightPanelVisible = true
        },

        /**
         * Save Event Settings
         *
         * Save the Accreditation Event Settings for the selected event.
         * This contains accreditation event config data such as the status, photo requirement, etc.
         *
         * @param selectedEvent {object} the selected event
         * @returns {Promise<void>}
         */
        async saveEventSettings(selectedEvent) {
            const t = this

            t.isLoading = true

            const RESPONSE = await t.MIX_redis_create('accreditationEvent', selectedEvent.accreditationEventData)

            t.isLoading = false

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error saving Accreditation Event Settings: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem saving the Event Settings, please try again.'
                return
            }

            // Reload the data
            await t.reloadData()

            // Update the selectedEvent data to update the UI on reload
            t.selectedEvent = selectedEvent

            t.closeRightPanel()
        },

        async savePasses(payload) {
            const t = this

            t.isLoading = true

            // Create Pass object
            const passesData = {
                usersAndStatuses: payload.usersAndStatuses,
                accreditationPassEventId: t.selectedEvent.eventData.entityId,
                accreditationPassOrganisationId: payload.organisationId
            }

            const RESPONSE = await t.MIX_redis_adminUpdateAccreditationPasses(passesData)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error saving Accreditation Passes: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem saving the Passes, please try again.'
                return
            }

            t.closeRightPanel()

            // Reload the data
            await t.reloadData()

            t.isLoading = false
        },

        async updatePassPrintStatuses(payload) {
            const t = this

            t.isLoading = true

            const RESPONSE = await t.MIX_redis_updateAccreditationPassPrintStatuses(payload)

            t.isLoading = false

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error updating Accreditation Pass Print Statuses: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem updating the Print Statuses, please try again.'
                return
            }

            t.$sharedState.successMessage = 'Print Statuses updated'

            // Reload the data
            await t.reloadData()

            t.closeRightPanel()
        }
    },

    mounted() {
        const t = this

        t.loadData()
    }
}
</script>

<style scoped></style>
