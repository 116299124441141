<template>
    <div class="appGrey">
        <div v-for="item in computedUsersData" :key="item.userData.entityId" class="appWhite d-flex align-center rounded-lg mb-4 pa-4">
            <!--Avatar-->
            <avatar class="mr-4" :file="item.fileData" size="60" :user="item.userData" />

            <!--User Details-->
            <div>
                <app-text>{{ item.userData.userName }}</app-text>
                <app-text color="grey9" size="small">{{ item.userData.userPosition }}</app-text>
                <app-text v-if="item?.userData?.userRole === 'Manager'" color="red" size="small">Manager</app-text>
            </div>

            <v-spacer />

            <!--Zones-->
            <div class="d-flex mx-4 pl-4" style="border-left: 1px solid lightgrey; border-right: 1px solid lightgrey">
                <!--Zone 1-->
                <div v-for="zone in parsedZones(item.userData.userDefaultZones)" :key="zone.zone" class="d-flex flex-column align-center mr-4">
                    <app-text size="small">{{ zone.zone }}</app-text>
                    <app-icon :color="zone.iconColor" :icon="zone.iconName" size="48" />
                </div>
            </div>

            <!--Arrow-->
            <app-icon @click.native="handleUserSelection(item)" color="primary" icon="arrowForward" size="32" />
        </div>
    </div>
</template>

<script>
//////////////////////////////////
//// ! Organisation Members ! ////
//////////////////////////////////

export default {
    name: 'OrganisationMembers',

    props: ['formData'],

    data: () => ({}),

    computed: {
        computedUsersData() {
            const t = this
            const USERS_DATA = t.$props.formData.usersData
            let usersData = []

            const MANAGERS = USERS_DATA.filter((user) => user.userData.userRole === 'Manager')
            const USERS = USERS_DATA.filter((user) => user.userData.userRole === 'User')

            // Sort the users by role
            MANAGERS.sort((a, b) => (a.userData.userName > b.userData.userName ? 1 : -1))
            USERS.sort((a, b) => (a.userData.userName > b.userData.userName ? 1 : -1))

            return (usersData = [...MANAGERS, ...USERS])
        }
    },

    methods: {
        /**
         * Get Zone Config
         *
         * Returns the zone config for the given zone
         *
         * @param zone {string} - The zone
         * @returns {{color: string, icon: string}} - The zone config
         */
        getZoneConfig(zone) {
            const t = this
            let color
            let icon

            if (zone.hasAccess) {
                color = 'green'
                icon = 'success'
            } else {
                color = 'red'
                icon = 'cancel'
            }

            return { color, icon }
        },

        handleUserSelection(item) {
            this.$emit('emitSelectedUser', item)
        },

        /**
         * Parsed Zones
         *
         * Parses the userDefaultZones data.
         * If the data is a string, it will be parsed, otherwise it will be returned as is.
         *
         * @param zonesData {string|*[]} - The userDefaultZones data
         * @returns {*[]} - The parsed userDefaultZones data
         */
        parsedZones(zonesData) {
            let result = []

            // If the data is a string, parse it
            if (typeof zonesData === 'string') {
                try {
                    result = JSON.parse(zonesData)
                } catch (error) {
                    console.error('Error parsing userDefaultZones: ', error)
                }
            }
            // If the data is an array, return it as is
            else if (Array.isArray(zonesData)) result = zonesData

            // Attach the config directly to each zone
            result = result.map((zone) => {
                const config = this.getZoneConfig(zone)
                return {
                    ...zone,
                    iconColor: config.color,
                    iconName: config.icon
                }
            })

            return result
        }
    }
}
</script>

<style scoped></style>
