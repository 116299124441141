import { render, staticRenderFns } from "./AccreditationEvents.vue?vue&type=template&id=042c2c37&scoped=true&"
import script from "./AccreditationEvents.vue?vue&type=script&lang=js&"
export * from "./AccreditationEvents.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "042c2c37",
  null
  
)

export default component.exports