<template>
    <div class="rounded-lg preEventCard-container">
        <!--Team Name | Confirmed | Quota | Balance | Reserves-->
        <div class="d-flex align-center justify-space-between pa-2">
            <!--Team Name-->
            <app-text size="small">{{ cardData.teamData.teamName }}</app-text>

            <!--Confirmed/Quota | Balance | Reserves-->
            <div class="d-flex align-center">
                <!--Confirmed/Quota-->
                <app-text color="grey9" size="small">
                    <strong>{{ cardData.eventTeamFiguresData.numberOfTeamMembers }}</strong>
                    /
                    {{ cardData.eventTeamData.eventTeamQuota }}
                </app-text>

                <!--Balance-->
                <app-text color="red" class="ml-2" size="small"> ({{ cardData.eventTeamFiguresData.teamBalance }}) </app-text>

                <!--Reserves Count-->
                <div class="ml-2 px-2 py-1 reserves-badge">
                    <app-text color="blue" size="small"> R: {{ cardData.eventTeamFiguresData.numberOfReservedMembers }} </app-text>
                </div>
            </div>
        </div>

        <!--Green Tick or Status Bars-->
        <div class="status-container" :class="{ 'status-container--over-quota': isOverQuota }">
            <!-- Show green tick if confirmed exceeds quota -->
            <div v-if="isOverQuota" class="tick-container d-flex align-center">
                <app-icon color="white" icon="success" size="90" />
            </div>

            <!-- Otherwise show progress bars -->
            <div v-else>
                <pre-event-progress-bar
                    color="green"
                    label="Confirmed"
                    :numerical-value="cardData.eventTeamFiguresData.numberOfConfirmedMembers"
                    :percent-value="calculateConfirmedPercentage(cardData.eventTeamFiguresData.numberOfConfirmedMembers)"
                />

                <pre-event-progress-bar
                    color="orange"
                    label="Available"
                    :numerical-value="cardData.eventTeamFiguresData.numberOfAvailableMembers"
                    :percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfAvailableMembers)"
                />

                <pre-event-progress-bar
                    color="blue"
                    label="Reserved"
                    :numerical-value="cardData.eventTeamFiguresData.numberOfReservedMembers"
                    :percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfReservedMembers)"
                />

                <pre-event-progress-bar
                    color="red"
                    label="Unavailable"
                    :numerical-value="cardData.eventTeamFiguresData.numberOfUnavailableMembers"
                    :percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfUnavailableMembers)"
                />

                <pre-event-progress-bar
                    color="grey6"
                    label="No Response"
                    :numerical-value="cardData.eventTeamFiguresData.numberOfNoResponseMembers"
                    :percent-value="calculatePercentage(cardData.eventTeamFiguresData.numberOfNoResponseMembers)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PreEventProgressBar from '@/views/preEventDashboard/preEventTeamCard/preEventProgressBar/PreEventProgressBar'

export default {
    name: 'PreEventTeamCard',

    props: ['cardData'],

    components: { PreEventProgressBar },

    computed: {
        /**
         * Is Over Quota
         *
         * Check if the number of confirmed members exceeds the team quota
         *
         * @returns {boolean} - true if confirmed members exceed quota
         */
        isOverQuota() {
            const confirmedMembers = this.cardData.eventTeamFiguresData.numberOfConfirmedMembers
            const quota = this.cardData.eventTeamData.eventTeamQuota
            return confirmedMembers >= quota
        }
    },

    methods: {
        /**
         * Calculate Confirmed Percentage
         *
         * Calculate the percentage of how many of the team members have a Confirmed status, out of the team quota.
         *
         * @param statusNumber - the number of team members with the given status
         * @returns {number} - the percentage value
         */
        calculateConfirmedPercentage(statusNumber) {
            const t = this
            const EVENT_TEAM_DATA = t.$props.cardData
            const TEAM_QUOTA = EVENT_TEAM_DATA.eventTeamData.eventTeamQuota

            return (statusNumber / TEAM_QUOTA) * 100
        },

        /**
         * Calculate Percentage
         *
         * Calculate the percentage of how many of the team members have the given status,
         * out of the number of team members.
         *
         * @param statusNumber - the number of team members with the given status
         * @returns {number} - the percentage value
         */
        calculatePercentage(statusNumber) {
            const t = this
            const EVENT_TEAM_DATA = t.$props.cardData
            const NUMBER_OF_TEAM_MEMBERS = EVENT_TEAM_DATA.eventTeamFiguresData.numberOfTeamMembers

            return (statusNumber / NUMBER_OF_TEAM_MEMBERS) * 100
        }
    }
}
</script>

<style scoped>
.preEventCard-container {
    background-color: var(--v-appWhite-base);
    border: 1px solid lightgrey;
}

/* Status container styles */
.status-container {
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
    border-radius: 0 0 8px 8px;
}

.status-container--over-quota {
    background-color: var(--v-green-base); /* Using Vue2's variable pattern for consistency */
}

.tick-container {
    width: 100%;
    height: 100%; /* Ensure container takes full height */
    display: flex; /* Use flexbox for alignment */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    height: 120px; /* Match height of progress bars section */
}
</style>
