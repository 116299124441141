<!-- User Form -->
<template>
    <div class="appGrey" style="min-height: 100%">
        {{ computedInit }}

        <!--Personal Details-->
        <div :class="$vuetify.breakpoint.width < 800 ? 'd-flex flex-column' : 'd-flex align-center'">
            <!--Profile Picture-->
            <div
                class="appWhite d-flex flex-column rounded-lg mr-4 pa-2"
                :style="$vuetify.breakpoint.width < 800 ? 'height: 272px; width: 100%' : 'aspect-ratio: 1/1; height: 272px; width: 272px'"
            >
                <!--Temporary Profile Picture-->
                <v-img
                    v-if="tempProfilePicture"
                    :src="tempProfilePicture"
                    class="rounded-lg"
                    max-height="272"
                    :style="$vuetify.breakpoint.width < 800 ? 'height: 256px' : 'aspect-ratio: 1/1; height: 256px; width: 256px'"
                />

                <!--Profile Picture-->
                <v-img
                    v-if="!tempProfilePicture && form.userProfilePicture"
                    :src="MIX_getImagePath('profilePictures', fullUserData.fileData.fileName, fullUserData.fileData.fileToken)"
                    class="rounded-lg"
                    max-height="272"
                    :style="$vuetify.breakpoint.width < 800 ? 'height: 256px' : 'aspect-ratio: 1/1; height: 256px; width: 256px'"
                />

                <!--Default Profile Picture-->
                <app-icon v-if="!form.userProfilePicture" cover class="d-flex align-self-center" icon="user" size="256" />

                <!--Image Upload button-->
                <image-upload
                    v-if="!isReadOnly"
                    @emitImageUpload="emittedImageUpload"
                    folder="profilePictures"
                    :id-for-image="form.entityId"
                    image-for="profilePicture"
                    style="position: absolute"
                />
            </div>

            <!--Personal Details-->
            <div style="width: 100%">
                <!--UserName-->
                <app-form-field
                    form-type="textInput"
                    :class="$vuetify.breakpoint.width < 800 ? 'mt-4' : ''"
                    :disabled="isReadOnly"
                    :error="errors.userName"
                    :error-message="errors.userNameErrorMessage"
                    label="Full Name"
                    v-model.trim="form.userName"
                />

                <!--Position-->
                <app-form-field
                    form-type="textInput"
                    class="mt-4"
                    :disabled="isReadOnly"
                    :error="errors.userPosition"
                    :error-message="errors.userPositionErrorMessage"
                    label="Position"
                    v-model.trim="form.userPosition"
                />

                <!--Email-->
                <app-form-field
                    form-type="textInput"
                    class="mt-4"
                    :disabled="true"
                    :error="errors.userEmail"
                    :error-message="errors.userEmailErrorMessage"
                    label="Email"
                    title="Email addresses cannot be changed at present."
                    v-model.trim="form.userEmail"
                />

                <!--Telephone-->
                <app-form-field
                    form-type="textInput"
                    class="mt-4"
                    :disabled="isReadOnly"
                    :error="errors.userTelephone"
                    :error-message="errors.userTelephoneErrorMessage"
                    label="Telephone"
                    v-model.trim="form.userTelephone"
                />
            </div>
        </div>

        <!--Employment-->
        <form-section-title class="mt-4" title="Employment" />
        <v-row no-gutters>
            <!--ID Number-->
            <v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''" :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
                <app-form-field form-type="textInput" :disabled="isReadOnly" label="ID Number (optional)" v-model.trim="form.userInternalIdNumber" />
            </v-col>

            <!--Pay Grade-->
            <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pr-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                <app-form-field
                    form-type="select"
                    :disabled="MIX_getCurrentUser().userLevel !== 'Staff-Admin' || isReadOnly"
                    :error="errors.userPayGrade"
                    :error-message="errors.userPayGradeErrorMessage"
                    :items="userPayGradeOptions"
                    label="Pay Grade"
                    v-model="form.userPayGrade"
                />
            </v-col>

            <!--Payroll Number-->
            <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'mt-4 pl-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                <app-form-field
                    form-type="textInput"
                    :disabled="MIX_getCurrentUser().userLevel !== 'Staff-Admin' || isReadOnly"
                    label="Payroll Number"
                    v-model="form.userPayrollNumber"
                />
            </v-col>
        </v-row>

        <!--Access & Abilities | Observation Responsibilities | Configuration-->
        <!--Only for Admin-->
        <div v-if="MIX_getCurrentUser().userLevel === 'Staff-Admin'">
            <!--Access & Abilities | Observation Responsibilities-->
            <form-section-title class="mt-4" title="Access & Abilities, and Observation Responsibilities" />
            <v-row no-gutters>
                <!--Access & Abilities-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                    <app-form-field
                        form-type="autoComplete"
                        :disabled="isReadOnly"
                        label="Access & Abilities"
                        v-model="form.userAccessAndAbilities"
                    />
                </v-col>

                <!--Observation Responsibilities-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 6">
                    <app-form-field
                        form-type="autoComplete"
                        :disabled="isReadOnly"
                        :items="userObservationResponsibilitiesOptions"
                        label="Observation Responsibilities"
                        :multiple="true"
                        v-model="form.userObservationResponsibilities"
                    />
                </v-col>
            </v-row>

            <!--Configuration-->
            <form-section-title class="mt-4" title="Configuration" />
            <v-row no-gutters>
                <!--User Type-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? '' : 'pr-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
                    <app-form-field
                        form-type="autoComplete"
                        :disabled="isReadOnly"
                        :error="errors.userType"
                        :error-message="errors.userTypeErrorMessage"
                        :items="userTypeOptions"
                        label="User Type"
                        v-model="form.userType"
                    />
                </v-col>

                <!--User Role-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'px-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
                    <app-form-field
                        form-type="autoComplete"
                        :disabled="isReadOnly"
                        :error="errors.userRole"
                        :error-message="errors.userRoleErrorMessage"
                        :items="computedUserRoleOptions"
                        label="User Role"
                        v-model="form.userRole"
                    />
                </v-col>

                <!--User Status-->
                <v-col :class="$vuetify.breakpoint.width < 600 ? 'mt-4' : 'pl-2'" :cols="$vuetify.breakpoint.width < 600 ? 12 : 4">
                    <app-form-field
                        form-type="autoComplete"
                        :disabled="isReadOnly"
                        :error="errors.userStatus"
                        :error-message="errors.userStatusErrorMessage"
                        label="User Status"
                        :items="userStatusOptions"
                        v-model="form.userStatus"
                    />
                </v-col>
            </v-row>

            <!--User Notes-->
            <form-section-title class="mt-4" title="User Notes" />
            <v-row no-gutters>
                <v-col :class="$vuetify.breakpoint.width < 600 ? '' : ''" :cols="$vuetify.breakpoint.width < 600 ? 12 : 12">
                    <app-form-field
                        form-type="textArea"
                        :disabled="MIX_getCurrentUser().userLevel !== 'Staff-Admin' || isReadOnly"
                        label="Notes"
                        v-model="form.userNotes"
                    />
                </v-col>
            </v-row>
        </div>

        <!--Save Button-->
        <div class="d-flex justify-end mt-4">
            <app-btn
                v-if="!isReadOnly"
                @click.native="handleSaveItem"
                color="green"
                icon="save"
                icon-color="white"
                label="Save"
                label-color="white"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserForm',

    props: ['fullUserData', 'isReadOnly'],

    data: () => ({
        errors: {
            userEmail: false,
            userEmailErrorMessage: '',
            userInternalIdNumber: false,
            userInternalIdNumberErrorMessage: '',
            userName: false,
            userNameErrorMessage: '',
            userPayGrade: false,
            userPayGradeErrorMessage: '',
            userPosition: false,
            userPositionErrorMessage: '',
            userRole: false,
            userRoleErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
            userType: false,
            userTypeErrorMessage: ''
        },
        // User form copied into Organisations - be aware of changes
        form: {
            entityId: '',
            userAcceptedTermsAndConditions: null,
            userAccessAndAbilities: [],
            userDefaultZones: '',
            userEmail: '',
            userFbId: '',
            userInternalIdNumber: '',
            userLastSwappDateTime: 0,
            userLastSwappLocationId: '',
            userLastSwappMethod: '',
            userLastSwappSiteId: '',
            userLevel: '',
            userName: '',
            userNotes: '',
            userObservationResponsibilities: [],
            userOrganisation: '',
            userPayGrade: '',
            userPayrollNumber: '',
            userPosition: '',
            userProfilePicture: '',
            userQualifications: [],
            userRole: '',
            userStatus: '',
            userSwappStatus: '',
            userTeams: '',
            userTelephone: '',
            userType: '',

            createdUserId: '',
            createdDateTime: 0,
            createdUserName: '',
            modifiedUserId: '',
            modifiedDateTime: 0,
            modifiedUserName: '',
            isDeleted: false,
            deletedUserId: '',
            deletedDateTime: 0,
            deletedUserName: ''
        },
        imageFolderName: 'profilePictures',
        tempProfilePicture: ''
    }),

    computed: {
        /**
         * Computed Init
         *
         * If there is any form data, assign it to the form.
         * If there is no form data, it will be blank (new).
         */
        computedInit() {
            const t = this
            const FORM_DATA = t.$props.fullUserData.userData

            if (FORM_DATA?.entityId) t.form = FORM_DATA
        },

        /**
         * Computed User Role Options
         *
         * Return the correct roles for the change user type.
         * If the user type has changed, clear the role.
         */
        computedUserRoleOptions() {
            const t = this
            const USER_TYPE = t.form.userType
            let userRoles = []

            // Staff
            if (USER_TYPE === 'Staff') {
                userRoles = ['Admin', 'Manager', 'Supervisor', 'User']

                if (!userRoles.includes(t.form.userRole)) t.form.userRole = ''
            }

            // Steward
            if (USER_TYPE === 'Steward') {
                userRoles = ['Supervisor', 'Management', 'Deputy', 'User']

                if (!userRoles.includes(t.form.userRole)) t.form.userRole = ''
            }

            // Organisation
            if (USER_TYPE === 'Organisation') {
                userRoles = ['Manager', 'User']

                if (!userRoles.includes(t.form.userRole)) t.form.userRole = ''
            }

            return userRoles
        }
    },

    methods: {
        /**
         * Clear Errors
         *
         * Clear all errors and their messages.
         */
        clearErrors() {
            const t = this

            for (const error in t.errors) {
                if (typeof t.errors[error] === 'string') t.errors[error] = ''
                if (typeof t.errors[error] === 'boolean') t.errors[error] = false
            }
        },

        /**
         * Create Item
         *
         * Create the form and save to the DB.
         */
        async createItem() {
            const t = this

            const RESPONSE = await t.MIX_redis_create('user', t.form)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error creating User: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem creating this User, please try again.'
                return
            }

            t.$sharedState.successMessage = 'Created User'
            t.$emit('emitReloadPage')
        },

        /**
         * Emitted Image Upload
         *
         * When an image is uploaded, break apart the downloadURL and save what's required.
         * Then, update the form with the image ID.
         *
         * @param payload - The image data and download URL
         */
        async emittedImageUpload(payload) {
            const t = this

            // Set the temp profile picture to render in the UI
            t.tempProfilePicture = payload.downloadURL

            const { FOLDER_NAME, IMAGE_NAME, TOKEN } = t.MIX_extractImageDataFromUrl(payload.downloadURL)

            // Save the image data to the DB (Files collection), and add the returned image ID to the form
            t.form.userProfilePicture = await t.saveImageData(t.form.entityId, FOLDER_NAME, IMAGE_NAME, payload.image.size, TOKEN, payload.image.type)
        },

        /**
         * Handle Save Item
         *
         * Handle whether to create or update the item to the DB, after it has passed validation.
         */
        handleSaveItem() {
            const t = this

            // Only save if the form is valid
            if (!t.validateForm()) return

            t.form.userLevel = `${t.form.userType}-${t.form.userRole}`
            t.form.userName = t.MIX_formatCapitaliseFirstLetters(t.form.userName)
            t.form.userPosition = t.MIX_formatCapitaliseFirstLetters(t.form.userPosition)
            t.form.userInternalIdNumber = t.form.userInternalIdNumber ? t.MIX_formatUserInternalIdNumber(t.form.userInternalIdNumber) : ''

            // Create
            if (!t.form.entityId) t.createItem()

            // Update
            if (t.form.entityId) t.updateItem()
        },

        /**
         * Save Image Data
         *
         * Save the image data to the DB (Files collection).
         *
         * @param fileDocLink {string} - The file's document link
         * @param fileFolder {string} - The storage folder
         * @param fileName {string} - The file name
         * @param fileSize {number} - The file size
         * @param fileToken {string} - The file token
         * @param fileType {string} - The file type
         * @returns {Promise<*>} - The image ID
         */
        async saveImageData(fileDocLink, fileFolder, fileName, fileSize, fileToken, fileType) {
            const t = this

            const IMAGE_DATA = {
                fileDocLink,
                fileFolder,
                fileName,
                fileSize,
                fileToken,
                fileType,
                createdDateTime: 0,
                createdUserId: '',
                createdUserName: '',
                modifiedDateTime: 0,
                modifiedUserId: '',
                modifiedUserName: '',
                isDeleted: false,
                deletedDateTime: 0,
                deletedUserId: '',
                deletedUserName: ''
            }

            const RESPONSE = await t.MIX_redis_create('file', IMAGE_DATA)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error saving File Data to DB:', RESPONSE.errors)
                t.$sharedState.errorMessage = 'There was a problem uploading your file, please try again.'
                return
            }

            return RESPONSE.data?.entityId
        },

        /**
         * Update Item
         *
         * Update the form and save to the DB.
         */
        async updateItem() {
            const t = this

            const RESPONSE = await t.MIX_redis_update('user', t.form.entityId, t.form)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error updating User: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem updating, please try again.'
                return
            }

            t.$sharedState.successMessage = 'Updated User'
            t.$emit('emitReloadPage')
        },

        /**
         * Validate Form
         *
         * Validate the form as required.
         *
         * @returns {boolean} if the form has passed validation, or not
         */
        validateForm() {
            const t = this

            t.clearErrors()

            // Name
            const USER_NAME = t.form.userName
            if (!USER_NAME) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name required'
            }

            // Position
            const USER_POSITION = t.form.userPosition
            if (!USER_POSITION) {
                t.errors.userPosition = true
                t.errors.userPositionErrorMessage = 'Position required'
            }

            // Email
            const USER_EMAIL = t.form.userEmail
            if (!USER_EMAIL) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!t.MIX_isEmailValid(USER_EMAIL)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email not valid'
            }

            // Telephone
            const USER_TELEPHONE_NUMBER = t.form.userTelephone
            if (!USER_TELEPHONE_NUMBER) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Telephone Number is required'
            } else {
                const VALIDATED_NUMBER = t.MIX_isTelephoneNumberValid(USER_TELEPHONE_NUMBER)
                if (!VALIDATED_NUMBER) {
                    t.errors.userTelephone = true
                    t.errors.userTelephoneErrorMessage = 'Invalid Telephone Number'
                } else {
                    t.form.userTelephone = VALIDATED_NUMBER
                }
            }

            // User Pay Grade
            if (!t.form.userPayGrade && t.form.userType !== 'Organisation') {
                t.errors.userPayGrade = true
                t.errors.userPayGradeErrorMessage = 'Pay Grade required'
            }

            // User Type
            if (!t.form.userType) {
                t.errors.userType = true
                t.errors.userTypeErrorMessage = 'User Type required'
            }

            // User Role
            if (!t.form.userRole) {
                t.errors.userRole = true
                t.errors.userRoleErrorMessage = 'User Role required'
            }

            // User Status
            if (!t.form.userStatus) {
                t.errors.userStatus = true
                t.errors.userStatusErrorMessage = 'User Status required'
            }

            return !Object.values(t.errors).includes(true)
        }
    }
}
</script>

<style scoped></style>
