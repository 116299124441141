<!-- My Accreditation -->
<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <!--Page Content-->
    <div v-else>
        <!--Events ------------------------------------------------------------------------------------------------- -->
        <my-accreditation-events v-if="viewType === 'Events'" @selectedEvent="handleSelectedEvent" :full-events-data="fullAccreditationEventsData" />

        <!--Landing Page ------------------------------------------------------------------------------------------- -->
        <my-accreditation-event-landing-page
            v-if="viewType === 'Landing Page'"
            @saveEventTCs="saveEventTCs"
            :full-event-data="fullOrganisationSingleAccreditationData"
        />

        <!--Users -------------------------------------------------------------------------------------------------- -->
        <my-accreditation-users
            v-if="viewType === 'Users'"
            @selectedUser="handleSelectedUser"
            :full-event-data="fullOrganisationSingleAccreditationData"
        />

        <!--Right Side ----------------------------------------------------------------------------------- Right Side-->
        <transition
            enter-active-class="animate__animated animate__fadeInRight animate__faster"
            leave-active-class="animate__animated animate__fadeOutRight animate__faster"
            mode="out-in"
        >
            <div
                v-if="isRightPanelVisible"
                class="appGrey formShadow"
                style="position: absolute; top: 0; bottom: 0; right: 0; overflow: auto"
                :style="$vuetify.breakpoint.width < 600 ? 'width: 100%' : 'width: 50%'"
                v-model="isRightPanelVisible"
            >
                <!--Header-->
                <div class="d-flex align-center primary pa-4">
                    <app-text color="appWhite" size="normal">Passes</app-text>
                    <v-spacer />
                    <app-icon @click.native="closeRightPanel" class="cursorPointer" color="white" icon="close" size="32" />
                </div>

                <v-divider />

                <!--Event Settings Form-->
                <my-accreditation-pass-form
                    @emitSavePasses="savePasses"
                    :selected-user="selectedUser"
                    :accreditation-data="fullOrganisationSingleAccreditationData"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import MyAccreditationEvents from '@/views/accreditation/organisation/myAccreditationEvents/MyAccreditationEvents.vue'
import MyAccreditationUsers from '@/views/accreditation/organisation/myAccreditationUsers/MyAccreditationUsers.vue'
import MyAccreditationEventLandingPage from '@/views/accreditation/organisation/myAccreditationEventLandingPage/MyAccreditationEventLandingPage.vue'
import MyAccreditationPassForm from '@/views/accreditation/organisation/myAccreditationPassForm/MyAccreditationPassForm.vue'

export default {
    name: 'MyAccreditation',

    components: {
        MyAccreditationPassForm,
        MyAccreditationEventLandingPage,
        MyAccreditationUsers,
        MyAccreditationEvents
    },

    data: () => ({
        isLoading: false,
        isRightPanelVisible: false,
        viewType: 'Events',
        selectedEvent: {},
        selectedUser: {},

        // Data
        fullAccreditationEventsData: [],
        fullOrganisationSingleAccreditationData: {}
    }),

    computed: {},

    methods: {
        /**
         * Close Right Panel
         *
         * Close the right panel.
         */
        closeRightPanel() {
            const t = this

            t.isRightPanelVisible = false
        },

        /**
         * Reload Data
         *
         * Reload the data to refresh the page.
         */
        async reloadData() {
            const t = this

            await Promise.all([t.loadData(), t.loadFullOrganisationSingleAccreditationData()])
        },

        /**
         * Handle Selected Event
         *
         * Events for Accreditation require Event data and Accreditation Event data.
         * Take the selected event and create the start of an Accreditation Event data object.
         * If the organisation has already accepted the Ts&Cs, show the Users, otherwise show the Landing Page.
         *
         * @param selectedEvent {object} the selected event (accreditationEventData, eventData)
         */
        async handleSelectedEvent(selectedEvent) {
            const t = this

            t.selectedEvent = { ...selectedEvent }

            await t.loadFullOrganisationSingleAccreditationData()

            // Get the Accreditation Event data for the selected event
            const ACCREDITATION_EVENT = t.fullAccreditationEventsData.find(
                (item) => item.accreditationEventData?.accreditationEventEventId === selectedEvent.accreditationEventData?.accreditationEventEventId
            )

            // If the organisation has already accepted the Ts&Cs, show the Users
            if (
                ACCREDITATION_EVENT.accreditationEventData.accreditationEventOrganisations.includes(
                    t.fullOrganisationSingleAccreditationData.organisationData.entityId
                )
            )
                t.viewType = 'Users'
            // Otherwise, show the Landing Page
            // else t.viewType = 'Landing Page'
            // This is not currently being used, but it's here for future use. Call saveEventTCs() to update the Accreditation Event list with this Organisation's ID
            else await t.saveEventTCs(t.fullOrganisationSingleAccreditationData)
        },

        handleSelectedUser(selectedUser) {
            const t = this

            t.selectedUser = { ...selectedUser }

            t.openRightPanel()
        },

        /**
         * Load Data
         *
         * Load all the data required for the page.
         *
         * @returns {Promise<void>}
         */
        async loadData() {
            const t = this

            t.isLoading = true

            await Promise.all([t.loadFullAccreditationEventsData()])

            t.isLoading = false
        },

        /**
         * Load Full Accreditation Events Data
         *
         * Load the required data from the database.
         *
         * @returns {Promise<void>}
         */
        async loadFullAccreditationEventsData() {
            const t = this

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getFullAccreditationEventsData()

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Full Accreditation Events data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Events data, please try again.'
                return
            }

            // Assign the data
            t.fullAccreditationEventsData = RESPONSE.data
        },

        /**
         * Load Full Organisation Single Accreditation Data
         *
         * Load all the data required for Accreditation Passes.
         *  - accreditationEventData {object}
         *  - eventData {object}
         *  - organisationData {object}
         *  - organisationUsersData [array]
         *  - passesData [array]
         *
         * @returns {Promise<void>}
         */
        async loadFullOrganisationSingleAccreditationData() {
            const t = this

            t.isLoading = true

            const EVENT_ID = t.selectedEvent.eventData.entityId
            const ORGANISATION_ID = t.MIX_getCurrentUser().userOrganisation

            // Fetch the data
            const RESPONSE = await t.MIX_redis_getFullOrganisationSingleAccreditationData(EVENT_ID, ORGANISATION_ID)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Full Organisation Single Accreditation Event data: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem getting the Accreditation data, please try again.'
                return
            }

            t.fullOrganisationSingleAccreditationData = RESPONSE.data

            t.isLoading = false
            t.closeRightPanel()
        },

        /**
         * Open Form
         *
         * Open the form dialog.
         */
        openRightPanel() {
            const t = this

            t.isRightPanelVisible = true
        },

        async saveEventSettings(selectedEvent) {
            const t = this

            const RESPONSE = await t.MIX_redis_create('accreditationEvent', selectedEvent.accreditationEventData)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error saving Accreditation Event Settings: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem saving the Event Settings, please try again.'
                return
            }

            // Reload the data
            await t.reloadData()

            // Update the selectedEvent data
            t.selectedEvent = selectedEvent

            t.closeRightPanel()
        },

        async saveEventTCs(selectedEvent) {
            const t = this
            const ACCREDITATION_EVENT_ID = selectedEvent.accreditationEventData.entityId
            const ORGANISATION_ID = selectedEvent.organisationData.entityId

            const RESPONSE = await t.MIX_redis_updateAccreditationEventOrganisation(ACCREDITATION_EVENT_ID, ORGANISATION_ID)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error updating Accreditation Event Organisation: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem saving the Event Settings, please try again.'
                return
            }

            t.viewType = 'Users'
        },

        async savePasses(payload) {
            const t = this

            const ORGANISATION_ID = t.MIX_getCurrentUser().userOrganisation

            // Create Pass object with individual user zones
            const passesData = {
                selectedUsers: payload.selectedUsers,
                accreditationPassEventId: t.selectedEvent.eventData.entityId,
                accreditationPassOrganisationId: ORGANISATION_ID,
                accreditationPassPrinted: false,
                accreditationPassStatus: 'Pending',
                userZones: payload.userZones // Pass through the individual user zones
            }

            const RESPONSE = await t.MIX_redis_createAccreditationPasses(passesData)

            if (RESPONSE.hasErrors) {
                console.error('Error saving Accreditation Passes:', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was a problem saving the Accreditation Passes, please try again.'
                return
            }

            await t.reloadData()
            t.closeRightPanel()
        }
    },

    mounted() {
        const t = this

        t.loadData()
    }
}
</script>

<style scoped></style>
