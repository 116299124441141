<!-- My Accreditation Pass Form -->
<template>
    <div class="pa-4">
        <!--Information Text-->
        <app-text size="small">
            Please select the users you require passes for. Once you have applied, your status will show as Pending until it has been Approved,
            Rejected, or you’ve been placed on the Reserve List.
            <br />
            The WRU Press Team will contact you by email to confirm your application.
            <br />
            <span class="grey9--text">Please note: If there is a high demand, we may bring the deadline forward.</span>
        </app-text>

        <!--Selected User-->
        <form-section-title v-if="selectedUser?.userData?.entityId" class="mt-8" title="Select User" />
        <div v-if="selectedUser?.userData?.entityId" class="d-flex align-center mt-4">
            <!--Checkbox-->
            <div
                @click="selectedUser?.passData?.accreditationPassStatus !== 'Approved' ? handleUserSelection(selectedUser) : null"
                class="appWhite d-flex align-center justify-center rounded-lg mr-4"
                :class="{
                    cursorPointer: selectedUser?.passData?.accreditationPassStatus !== 'Approved',
                    'cursor-not-allowed': selectedUser?.passData?.accreditationPassStatus === 'Approved'
                }"
                :style="selectedUser?.passData?.accreditationPassStatus === 'Approved' ? 'opacity: 0.7' : ''"
                style="aspect-ratio: 1/1; height: 56px; width: 56px"
            >
                <app-icon color="green" :icon="selectedUsers.includes(selectedUser.userData.entityId) ? 'check' : ''" size="32" />
            </div>

            <avatar class="mr-4" :file="selectedUser.fileData" :size="56" :user="selectedUser.userData" />

            <!--Name | Position-->
            <div>
                <!--Name-->
                <app-text size="normal-bold">{{ selectedUser.userData.userName }}</app-text>

                <!--Position-->
                <app-text color="grey9" size="small">{{ selectedUser.userData.userPosition }}</app-text>
            </div>

            <v-spacer />

            <!--Pass Status-->
            <div class="d-flex flex-column align-center">
                <!--Icon-->
                <app-icon :color="getStatusConfig(selectedUser).colour" :icon="getStatusConfig(selectedUser).icon" size="32" />

                <!--Text-->
                <app-text size="small">{{ selectedUser.passData.accreditationPassStatus }}</app-text>
            </div>
        </div>

        <!-- Zones -->
        <div
            v-if="selectedUser?.userData"
            class="d-flex ml-auto mt-4"
            style="display: grid !important; grid-template-columns: repeat(5, 1fr); grid-gap: 16px"
        >
            <div
                v-for="(zone, index) in parseZones(selectedUser?.passData?.accreditationPassZones || getDefaultZones())"
                :key="zone.zone"
                @click="selectedUser?.passData?.accreditationPassStatus !== 'Approved' ? handleZoneSelection(zone) : null"
                class="d-flex flex-column align-center appWhite rounded-lg pa-1"
                :class="{
                    cursorPointer: selectedUser?.passData?.accreditationPassStatus !== 'Approved',
                    'cursor-not-allowed': selectedUser?.passData?.accreditationPassStatus === 'Approved'
                }"
                :style="selectedUser?.passData?.accreditationPassStatus === 'Approved' ? 'opacity: 0.7; cursor: not-allowed' : ''"
            >
                <app-text size="small">{{ zone.zone }}</app-text>
                <app-icon
                    :color="zone.hasAccess ? 'green' : 'red'"
                    :icon="zone.hasAccess ? 'success' : 'cancel'"
                    :size="$vuetify.breakpoint.width < 600 ? 32 : 48"
                />
            </div>
        </div>
        <app-text v-if="selectedUser?.passData?.accreditationPassStatus === 'Approved'" color="grey9" size="small" class="my-2">
            Approved passes cannot be edited
        </app-text>

        <!--Users Applied-->
        <form-section-title class="mt-8" description="Other Users from your Organisation that have applied for passes." title="Users Applied" />
        <app-text v-if="!computedUsersApplied.length" class="text-center" color="grey9"> There are no other Users to show. </app-text>
        <div v-for="item in computedUsersApplied" :key="item.passData.entityId">
            <div class="d-flex align-center mt-4">
                <!--Checkbox-->
                <div
                    @click="item.passData?.accreditationPassStatus !== 'Approved' ? handleUserSelection(item) : null"
                    class="appWhite d-flex align-center justify-center rounded-lg mr-4"
                    :class="{
                        cursorPointer: item.passData?.accreditationPassStatus !== 'Approved',
                        'cursor-not-allowed': item.passData?.accreditationPassStatus === 'Approved'
                    }"
                    :style="item.passData?.accreditationPassStatus === 'Approved' ? 'opacity: 0.7' : ''"
                    style="aspect-ratio: 1/1; height: 56px; width: 56px"
                >
                    <app-icon color="green" :icon="selectedUsers.includes(item.userData.entityId) ? 'check' : ''" size="32" />
                </div>

                <avatar class="mr-4" :file="item.fileData" :size="56" :user="item.userData" />

                <!--Name | Position-->
                <div>
                    <!--Name-->
                    <app-text size="normal-bold">{{ item.userData.userName }}</app-text>

                    <!--Position-->
                    <app-text color="grey9" size="small">{{ item.userData.userPosition }}</app-text>
                </div>

                <v-spacer />

                <!--Pass Status-->
                <div class="d-flex flex-column align-center">
                    <!--Icon-->
                    <app-icon :color="getStatusConfig(item).colour" :icon="getStatusConfig(item).icon" size="32" />

                    <!--Text-->
                    <app-text size="small">{{ item.passData.accreditationPassStatus }}</app-text>
                </div>
            </div>

            <!-- Zones Grid -->
            <div class="d-flex ml-auto mt-4" style="display: grid !important; grid-template-columns: repeat(5, 1fr); grid-gap: 16px">
                <!-- Zones Grid for applied users -->
                <div
                    v-for="zone in parseZones(
                        item.passData?.accreditationPassZones || item.userData?.userDefaultZones || JSON.stringify(getDefaultZones())
                    )"
                    :key="zone.zone"
                    @click="item.passData?.accreditationPassStatus !== 'Approved' ? handleZoneSelection(zone, item) : null"
                    class="d-flex flex-column align-center appWhite rounded-lg pa-1"
                    :class="{
                        cursorPointer: item.passData?.accreditationPassStatus !== 'Approved',
                        'cursor-not-allowed': item.passData?.accreditationPassStatus === 'Approved'
                    }"
                    :style="item.passData?.accreditationPassStatus === 'Approved' ? 'opacity: 0.7; cursor: not-allowed' : ''"
                >
                    <app-text size="small">{{ zone.zone }}</app-text>
                    <app-icon
                        :color="zone.hasAccess ? 'green' : 'red'"
                        :icon="zone.hasAccess ? 'success' : 'cancel'"
                        :size="$vuetify.breakpoint.width < 600 ? 32 : 48"
                    />
                </div>
            </div>

            <app-text v-if="item.passData?.accreditationPassStatus === 'Approved'" color="grey9" size="small" class="my-2">
                Approved passes cannot be edited
            </app-text>

            <v-divider class="greyD mt-4" />
        </div>

        <!--Users Not Applied-->
        <form-section-title
            class="mt-8"
            description="Other Users from your Organisation that have NOT applied for passes."
            title="Users Not Yet Applied"
        />
        <app-text v-if="!computedUsersNotApplied.length" class="text-center" color="grey9"> There are no other Users to show. </app-text>
        <div v-for="item in computedUsersNotApplied" :key="item.entityId">
            <div class="d-flex align-center mt-4">
                <!--Checkbox-->
                <div
                    @click="handleUserSelection(item)"
                    class="appWhite d-flex align-center justify-center rounded-lg mr-4"
                    style="aspect-ratio: 1/1; height: 56px; width: 56px"
                >
                    <app-icon color="green" :icon="selectedUsers.includes(item.userData.entityId) ? 'check' : ''" size="32" />
                </div>

                <avatar class="mr-4" :file="item.fileData" :size="56" :user="item.userData" />

                <!--Name | Position-->
                <div>
                    <!--Name-->
                    <app-text size="normal-bold">{{ item.userData.userName }}</app-text>

                    <!--Position-->
                    <app-text color="grey9" size="small">{{ item.userData.userPosition }}</app-text>
                </div>
            </div>

            <!--Zones Grid-->
            <div class="d-flex ml-auto mt-4" style="display: grid !important; grid-template-columns: repeat(5, 1fr); grid-gap: 16px">
                <!-- Zones Grid for non-applied users -->
                <div
                    v-for="zone in parseZones(item.userData?.userDefaultZones || JSON.stringify(getDefaultZones()))"
                    :key="zone.zone"
                    @click="handleZoneSelection(zone, item)"
                    class="d-flex flex-column align-center appWhite rounded-lg pa-1 cursorPointer"
                >
                    <app-text size="small">{{ zone.zone }}</app-text>
                    <app-icon
                        :color="zone.hasAccess ? 'green' : 'red'"
                        :icon="zone.hasAccess ? 'success' : 'cancel'"
                        :size="$vuetify.breakpoint.width < 600 ? 32 : 48"
                    />
                </div>
            </div>

            <v-divider class="greyD mt-4" />
        </div>

        <!--Save Button-->
        <div class="d-flex justify-end mt-4">
            <app-btn @click.native="savePasses" color="green" :disabled="computedIsButtonDisabled" icon="save" label="Save" />
        </div>

        <!--Dialogs ------------------------------------------------------------------------------------------------ -->
        <v-dialog max-width="512" v-model="isRemovedUsersDialogVisible">
            <div class="appGrey rounded-lg pa-4">
                <app-text>You are about to delete the passes for the following people:</app-text>

                <app-text v-for="item in removedUserNames" class="mt-4 ml-4" size="normal-bold">- {{ item }}</app-text>

                <app-text class="mt-4" size="normal-bold">Are you sure you want to delete these passes?</app-text>

                <div class="d-flex justify-space-between mt-4">
                    <!--No-->
                    <app-btn @click.native="isRemovedUsersDialogVisible = false" color="grey" icon="cancel" label="No" />

                    <!--Yes-->
                    <app-btn @click.native="confirmDeletePasses" color="red" icon="delete" label="Yes" />
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'MyAccreditationPassForm',

    props: ['accreditationData', 'selectedUser'],

    data: () => ({
        isRemovedUsersDialogVisible: false,
        removedUserNames: [],
        selectedUsers: [],
        userZones: {},
        usersAndStatuses: [],
        userZoneChanges: {},
        latestZones: {}
    }),

    computed: {
        /**
         * Computed Is Button Disabled
         *
         * Return true if both conditions are met:
         * 1. No changes to selected users
         * 2. No changes to zones
         *
         * @returns {boolean}
         */
        computedIsButtonDisabled() {
            const t = this

            // Check for user selection changes
            const ORIGINAL_SELECTED_USERS = t.$props.accreditationData.passesData.map((item) => item.userData.entityId)
            const NEW_SELECTED_USERS = t.selectedUsers
            const hasUserSelectionChanges = JSON.stringify(ORIGINAL_SELECTED_USERS) !== JSON.stringify(NEW_SELECTED_USERS)

            // Check if we have any zone changes at all
            const hasZoneChanges = Object.keys(t.userZoneChanges).length > 0

            // If we have zone changes, enable the button regardless of user selection
            if (hasZoneChanges) {
                return false
            }

            // Otherwise check for user selection changes
            return !hasUserSelectionChanges
        },

        /**
         * Computed Users Applied
         *
         * Return the users that have applied for the accreditation pass.
         * Also, remove the current user from the list as they are displayed separately.
         *
         * @returns {array} - An array of objects containing the pass data (fileData{}, passData{}, userData{})
         */
        computedUsersApplied() {
            const t = this
            let tableData = t.$props.accreditationData.passesData || []

            // Remove the current user, if they are in the list
            if (t.selectedUser.userData?.entityId) tableData = tableData.filter((item) => item.userData.entityId !== t.selectedUser.userData.entityId)

            // Sort by UserName
            tableData = tableData.sort((a, b) => (a.userData.userName > b.userData.userName ? 1 : -1))

            return tableData
        },

        /**
         * Computed Users Not Applied
         *
         * Return the users that have not applied for the accreditation pass.
         * Also, remove the current user from the list as they are displayed separately.
         *
         * @returns {array} - An array of objects containing the Organisation Users data (fileData{}, userData{})
         */
        computedUsersNotApplied() {
            const t = this
            let tableData = t.$props.accreditationData.organisationUsersData || []

            // Remove the current user, if they are in the list
            tableData = tableData.filter((item) => item.userData.entityId !== t.selectedUser.userData?.entityId)

            // Sort by UserName
            tableData = tableData.sort((a, b) => (a.userData.userName > b.userData.userName ? 1 : -1))

            return tableData
        }
    },

    methods: {
        getDefaultZones() {
            const t = this
            // Get organization's default zones from accreditation data
            const orgDefaultZones = t.$props.accreditationData.organisationData?.organisationDefaultZones

            // If organization has default zones defined, use those
            if (orgDefaultZones) {
                try {
                    // Handle case where it might be stored as a string
                    if (typeof orgDefaultZones === 'string') {
                        return JSON.parse(orgDefaultZones)
                    }
                    // Handle case where it's already an array
                    if (Array.isArray(orgDefaultZones)) {
                        return orgDefaultZones
                    }
                } catch (error) {
                    console.error('Error parsing organisation default zones:', error)
                }
            }

            // If we couldn't get organization defaults, log error and return empty array
            console.error('No default zones found for organisation')
            return []
        },

        parseZones(zonesData) {
            // If no data is provided, return default zones
            if (!zonesData) {
                return this.getDefaultZones()
            }

            try {
                // If the data is already an array, return it
                if (Array.isArray(zonesData)) {
                    return zonesData
                }

                // If the data is a string, try to parse it
                if (typeof zonesData === 'string') {
                    const parsed = JSON.parse(zonesData)
                    // Check if parsed result is an array
                    if (Array.isArray(parsed)) {
                        return parsed
                    }
                }

                // If we get here, return default zones
                return this.getDefaultZones()
            } catch (error) {
                console.error('Error parsing zones:', error)
                return this.getDefaultZones()
            }
        },
        /**
         * Confirm Delete Passes
         *
         * On confirming the removal of passes, emit the savePasses event back to the parent.
         */
        confirmDeletePasses() {
            const t = this

            t.isRemovedUsersDialogVisible = false

            // Create payload with remaining users and their zones
            const payload = {
                selectedUsers: t.selectedUsers,
                zones: {}
            }

            // Add zones for remaining selected users
            t.selectedUsers.forEach((userId) => {
                payload.zones[userId] = t.userZones[userId] || t.getDefaultZones() // Fallback to default zones if none set
            })

            t.$emit('emitSavePasses', payload)
        },

        /**
         * Get Status Config
         *
         * Return the colour and icon for the pass status.
         *
         * @param item {Object} - The pass object {fileData{}, passData{}, userData{}
         * @returns {{colour: string, icon: string}}
         */
        getStatusConfig(item) {
            let color
            let icon

            switch (item.passData.accreditationPassStatus) {
                case 'Approved':
                    color = 'green'
                    icon = 'success'
                    break
                case 'Rejected':
                    color = 'red'
                    icon = 'close'
                    break
                case 'Pending':
                    color = 'orange'
                    icon = 'pending'
                    break
                case 'Reserved':
                    color = 'blue'
                    icon = 'reserved'
                    break
                default:
                    color = 'grey9'
                    icon = 'help'
                    break
            }

            return { colour: color, icon: icon }
        },

        /**
         * Handle User Selection
         *
         * Add or remove the selectedUser from the selectedUsers array.
         *
         * @param item {Object} - The pass object {fileData{}, passData{}, userData{}}
         */
        handleUserSelection(item) {
            const t = this

            // Add early return for approved passes
            if (item?.passData?.accreditationPassStatus === 'Approved') {
                return
            }

            // If the user is already selected, remove them from the array
            if (t.selectedUsers.includes(item.userData.entityId)) {
                t.selectedUsers = t.selectedUsers.filter((user) => user !== item.userData.entityId)
            }
            // Otherwise, add them to the array
            else {
                t.selectedUsers.push(item.userData.entityId)
            }
        },

        getDefaultZonesForUser(userId) {
            const t = this
            const user = t.$props.accreditationData.passesData.find((item) => item.userData.entityId === userId)
            return user?.passData?.accreditationPassZones || user?.userData?.userDefaultZones || JSON.stringify(t.getDefaultZones())
        },
        /**
         * Save Passes
         *
         * Check if the selectedUsers array is different to the original.
         * If new Users have been added, emit the savePasses event back to the parent for Pass creation.
         * If Users have been removed, show the dialog and emit the savePasses event back to the parent for Pass deletion.
         */
        savePasses() {
            const t = this

            if (t.computedIsButtonDisabled) return

            const ORIGINAL_SELECTED_USERS = t.$props.accreditationData.passesData.map((item) => item.userData.entityId)
            const NEW_SELECTED_USERS = t.selectedUsers

            // If there are user removals, handle them first
            if (JSON.stringify(ORIGINAL_SELECTED_USERS) !== JSON.stringify(NEW_SELECTED_USERS)) {
                const REMOVED_USER_IDS = ORIGINAL_SELECTED_USERS.filter((userId) => !NEW_SELECTED_USERS.includes(userId))

                if (REMOVED_USER_IDS.length) {
                    t.removedUserNames = t.$props.accreditationData.passesData
                        .filter((item) => REMOVED_USER_IDS.includes(item.userData.entityId))
                        .map((item) => item.userData.userName)
                    t.isRemovedUsersDialogVisible = true
                    return
                }
            }

            // Get all users that need to be included (selected users + users with zone changes)
            const usersToUpdate = [...new Set([...NEW_SELECTED_USERS, ...Object.keys(t.userZoneChanges)])]

            // Create userZonesMap for all affected users
            const userZonesMap = {}
            usersToUpdate.forEach((userId) => {
                userZonesMap[userId] = t.userZoneChanges[userId] || t.getDefaultZonesForUser(userId)
            })

            const userData = {
                selectedUsers: usersToUpdate,
                userZones: userZonesMap
            }

            t.$emit('emitSavePasses', userData)
        },
        handleZoneSelection(zone, user) {
            const t = this
            const targetUser = user || t.selectedUser

            if (targetUser?.passData?.accreditationPassStatus === 'Approved') {
                return
            }

            if (!targetUser?.userData?.entityId) {
                return
            }

            // Get current zones
            const currentZones = t.parseZones(
                targetUser.passData?.accreditationPassZones || targetUser.userData.userDefaultZones || JSON.stringify(t.getDefaultZones())
            )

            // Update zones
            const updatedZones = currentZones.map((z) => (z.zone === zone.zone ? { ...z, hasAccess: !z.hasAccess } : z))
            const updatedZonesString = JSON.stringify(updatedZones)

            // Store in component state for tracking changes
            t.$set(t.userZoneChanges, targetUser.userData.entityId, updatedZonesString)

            // Update UI state
            if (targetUser.passData) {
                targetUser.passData.accreditationPassZones = updatedZonesString
            } else {
                targetUser.userData.userDefaultZones = updatedZonesString
            }

            // Force computed property to re-evaluate
            t.$forceUpdate()
        }
    },

    mounted() {
        const t = this

        t.selectedUsers = t.$props.accreditationData.passesData.map((item) => item.userData.entityId)
    }
}
</script>

<style scoped>
.cursor-not-allowed {
    cursor: not-allowed;
}
</style>
