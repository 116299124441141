import { render, staticRenderFns } from "./PreEventTeamCard.vue?vue&type=template&id=af152b10&scoped=true&"
import script from "./PreEventTeamCard.vue?vue&type=script&lang=js&"
export * from "./PreEventTeamCard.vue?vue&type=script&lang=js&"
import style0 from "./PreEventTeamCard.vue?vue&type=style&index=0&id=af152b10&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af152b10",
  null
  
)

export default component.exports