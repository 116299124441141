<template>
    <page-loading-animation v-if="isLoading" :is-loading="isLoading" />

    <!--Page Content-->
    <div v-else>
        <component is="style"> @media print { @page { margin: 0; size: 200mm 297mm; } .noPrint { display: none; } } </component>
        <v-card class="accPass-card" flat id="page">
            <!--Toolbar-->
            <v-toolbar class="noPrint px-3" color="appGrey">
                <app-text color="primary" size="medium-bold">Pass Print Preview</app-text>

                <v-spacer />

                <app-btn
                    @click.native="printPass('printableArea')"
                    color="primary"
                    height="40"
                    icon="print"
                    icon-color="white"
                    label="Print"
                    label-color="white"
                />

                <close-icon class="ml-4" @click.native="closeDialog" />
            </v-toolbar>

            <!--Body-->
            <component is="style"> .accPass-container-outer { } </component>
            <div class="accPass-container-outer" id="printableArea">
                <!-- -------------------------------------- -->
                <!-- Everything from here down gets printed -->
                <!-- -------------------------------------- -->

                <!--Main Container-->
                <component is="style">
                    .accPass-container { background: white; display: grid; grid-template-rows: 1fr auto 1fr;
                    <!--border: 1px solid lightgrey;-->
                    margin: -2mm 0;
                    <!--padding: 4mm;-->
                    height: 297mm; width: 200mm; }
                </component>
                <div v-for="item in passesData" class="accPass-container">
                    <!--Event Background-->
                    <component is="style"> .accPass-eventBackground-container { border-radius: 2mm; } </component>
                    <img
                        :src="MIX_getImagePath('eventBackground', eventImageData.fileName, eventImageData.fileToken)"
                        alt="event background"
                        class="accPass-eventBackground-container"
                        height="512px"
                        width="100%"
                    />

                    <!--User Details-->
                    <component is="style">
                        .accPass-userDetails-container { display: grid; grid-template-columns: 1fr 62mm; margin: 4mm 0; height: 62mm; }
                    </component>
                    <div class="accPass-userDetails-container">
                        <!--User Text-->
                        <component is="style">
                            .accPass-userText-container { display: grid; grid-template-rows: 1fr auto auto; margin-right: 4mm; }
                        </component>
                        <div class="accPass-userText-container">
                            <component is="style">
                                .accPass-user-text { font-family: Avenir, Helvetica, Arial, sans-serif; -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale; color: #333333; font-size: 24pt; line-height: 36pt; }
                            </component>

                            <!--Contact Name-->
                            <app-text
                                class="accPass-user-text"
                                :style="{ 'font-size': calculateFontSize(item.userData.userName), fontWeight: 'bold', lineHeight: '104px' }"
                            >
                                {{ item.userData.userName }}
                            </app-text>

                            <!--Organisation Name-->
                            <app-text class="accPass-user-text">{{ item.organisationData.organisationName }}</app-text>

                            <!--Position-->
                            <app-text class="accPass-user-text" style="color: #999999">{{ item.userData.userPosition }} </app-text>
                        </div>

                        <!--User Profile Picture-->
                        <component is="style">
                            .accPass-profilePicture { border-radius: 2mm; aspect-ratio: 1; object-fit: cover; width: 62mm; }
                        </component>
                        <img
                            :src="MIX_getImagePath('profilePictures', item.userProfilePicture?.fileName, item.userProfilePicture?.fileToken)"
                            alt="profile picture"
                            class="accPass-profilePicture"
                            height="100%"
                            width="100%"
                        />
                    </div>

                    <!--Zones-->
                    <component is="style">
                        .accPass-zones-container { border-radius: 4mm; background: #CC0E23; display: grid; grid-template-rows: repeat(2, 1fr);
                        grid-template-columns: repeat(3, 1fr); grid-gap: 4mm; padding: 4mm 4mm 4mm 4mm; margin: 0 4mm 4mm 4mm; }
                    </component>
                    <div class="accPass-zones-container">
                        <!--Child Container-->
                        <component is="style">
                            .accPass-pass-zones-child-container { background: white; border: 1px solid #CC0E23; display: flex; flex-direction: column;
                            align-items: center; justify-content: space-around; border-radius: 4mm; padding: 1mm; }
                        </component>

                        <!--Child Number-->
                        <component is="style">
                            .accPass-zone-numbers { color: #CC0E23; font-family: Avenir, Helvetica, Arial, sans-serif; -webkit-font-smoothing:
                            antialiased; -moz-osx-font-smoothing: grayscale; font-size: 32pt !important; font-weight: bold; }
                        </component>

                        <!--Zone 1-->
                        <div class="accPass-pass-zones-child-container">
                            <app-text
                                v-if="
                                    isValidJSON(item.passData.accreditationPassZones) &&
                                    JSON.parse(item.passData.accreditationPassZones)[0]?.hasAccess
                                "
                                class="accPass-zone-numbers"
                                color="#"
                                >1</app-text
                            >
                        </div>
                        <!--Zone 2-->
                        <div class="accPass-pass-zones-child-container">
                            <app-text
                                v-if="
                                    isValidJSON(item.passData.accreditationPassZones) &&
                                    JSON.parse(item.passData.accreditationPassZones)[1]?.hasAccess
                                "
                                class="accPass-zone-numbers"
                                color="#"
                                >2</app-text
                            >
                        </div>
                        <!--Zone 3-->
                        <div class="accPass-pass-zones-child-container">
                            <app-text
                                v-if="
                                    isValidJSON(item.passData.accreditationPassZones) &&
                                    JSON.parse(item.passData.accreditationPassZones)[2]?.hasAccess
                                "
                                class="accPass-zone-numbers"
                                color="#"
                                >3</app-text
                            >
                        </div>
                        <!--Zone 4-->
                        <div class="accPass-pass-zones-child-container">
                            <app-text
                                v-if="
                                    isValidJSON(item.passData.accreditationPassZones) &&
                                    JSON.parse(item.passData.accreditationPassZones)[3]?.hasAccess
                                "
                                class="accPass-zone-numbers"
                                color="#"
                                >4</app-text
                            >
                        </div>
                        <!--Zone 5-->
                        <div class="accPass-pass-zones-child-container">
                            <app-text
                                v-if="
                                    isValidJSON(item.passData.accreditationPassZones) &&
                                    JSON.parse(item.passData.accreditationPassZones)[4]?.hasAccess
                                "
                                class="accPass-zone-numbers"
                                color="#"
                                >5</app-text
                            >
                        </div>

                        <!--Logo-->
                        <component is="style">
                            .accPass-zones-wrapper { display: flex; justify-content: center; align-items: center; width: 100%; /* Adjust this to the
                            desired width of the pass */ height: 100%; /* Height of the container */ } .accPass-zones-image-container { display: flex;
                            align-items: center; justify-content: center; background-size: contain; background-repeat: no-repeat; background-position:
                            center; width: 128px; /* Keep the width and height for the logo */ height: 112px; }
                        </component>

                        <div class="accPass-zones-wrapper">
                            <div class="accPass-zones-image-container" :style="{ 'background-image': 'url(' + getCompanyLogo() + ')' }"></div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
export default {
    // name: "organisationPassPrintPreview",
    name: 'AccreditationPrintPass_principality',

    props: ['eventImageData', 'passesData'],

    data: () => ({
        isLoading: false
    }),

    methods: {
        closeDialog() {
            const t = this

            t.$emit('closeDialog', t.$props.passesData)
        },

        getCompanyLogo() {
            try {
                // Try to load the main image
                return require('@/assets/images/company-logo-sofiagardens.svg')
            } catch (e) {
                // If it fails, load the fallback image
                return require('@/assets/images/company-logo-white.svg')
            }
        },

        isValidJSON(str) {
            try {
                return JSON.parse(str)
            } catch (e) {
                console.error('Invalid JSON input:', str)
                return false
            }
        },

        async loadData() {
            const t = this

            t.isLoading = true

            await Promise.all([t.loadUserImageFiles()])

            t.isLoading = false
        },

        async loadUserImageFiles() {
            const t = this

            // Get all the user profile picture IDs
            const USERS_PROFILE_PICTURE_IDS = this.$props.passesData.map((item) => item.userData.userProfilePicture)

            console.log('passesData :', JSON.stringify(this.$props.passesData, null, 2))

            const RESPONSE = await t.MIX_redis_getEntitiesByIds('file', USERS_PROFILE_PICTURE_IDS)

            // Handle any errors
            if (RESPONSE.hasErrors) {
                console.error('Error getting Entities by IDs: ', RESPONSE.error)
                t.$sharedState.errorMessage = 'There was an error loading the user images, please try again.'
                return
            }

            const USER_FILES_DATA = RESPONSE.data

            // Loop through the user data and add the profile picture to the passesData
            t.$props.passesData.map((item) => {
                item.userProfilePicture = USER_FILES_DATA.find((file) => file.entityId === item.userData.userProfilePicture)
                return item
            })
        },

        printPass(printableArea) {
            const DIV_CONTENTS = document.getElementById(printableArea).innerHTML
            const WIN = window.open('', '', 'height=600, width=960')
            WIN.document.write(DIV_CONTENTS)

            setTimeout(() => {
                WIN.document.close()
                WIN.print()
            }, 1000)
        },

        calculateFontSize(text) {
            const widthOfContainer = 800
            let fontSize = widthOfContainer / text.length

            if (fontSize > 104) fontSize = 104

            return fontSize + 'px'
        }
    },

    async mounted() {
        const t = this

        await t.loadData()
    }
}
</script>

<style scoped>
@media print {
    @page {
        margin: 0;

        size: 200mm 297mm;
    }

    .noPrint {
        display: none;
    }
}
</style>
